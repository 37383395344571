import React, { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import Avatar from 'react-avatar';
import { TableOptions, useTable, useExpanded, useGroupBy, useGlobalFilter } from 'react-table';
import { useSetState } from 'utils';
import TabSet from 'components/TabSet';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'elements/Modal';
import ModalConfirm from 'elements/Modal/ModalConfirm';
import DateDropdown from 'elements/DateDropdown';
import Button from 'elements/Button';
import Icon from 'assets/Icon';
import { TextField } from 'elements/Forms';
import ActionMenu from 'elements/ActionMenu';
import { ListProps } from 'types/global';
import { getCategories } from 'services/categoryV1/categories';
import { getAccounts } from 'services/accountV1/accounts';
import { getTransactions, TransactionProps, deleteTransactions, postTransactions, patchTransactions } from 'services/accountV1/transactions';
import Transfer from './components/Transfer';
import Income from './components/Income';
import Expense from './components/Expense';
// Style
import styles from './Transactions.module.css';

function Transactions() {
  const defaultState = {
    loadingData: true,
    submitData: false,
    activeRow: {} as TransactionProps,
    tabs: ['Expense', 'Income', 'Transfer'],
    transactionData: [] as TransactionProps[],
    expenseCategoryList: [] as ListProps[],
    incomeCategoryList: [] as ListProps[],
    transferCategoryList: [] as ListProps[],
    accountList: [] as ListProps[],
    modalType: '',
  };
  const [state, setState] = useSetState(defaultState);
  const [filterDate, setFilterDate] = useState(moment().toDate());
  const [filterString, setFilterString] = useState('');
  const [transactionModalState, setTransactionModalState] = useState(false);
  const [confirmModalState, setConfirmModalState] = useState(false);
  const autoExpended = useRef(true);
  const expandedRow = useRef({});

  const setActiveRow = (valueObj: Partial<TransactionProps>) => {
    setState({ activeRow: { ...state.activeRow, ...valueObj } })
  }

  const handleFilterDate = (date: Date) => {
    setFilterDate(date);
    setState({ submitData: true });
  }

  const handleFilterString = (val: string) => {
    setFilterString(val);
  }

  const toggleTransactionModal = (active: boolean) => {
    setTransactionModalState(active);
  };

  const toggleConfirmModal = (active: boolean) => {
    setConfirmModalState(active);
  };

  const addAction = () => {
    setState({
      activeRow: {
        transactionDate: moment().toDate(),
        bookId: 9,
        baseTransactionType: 'EXPENSE',
      },
      modalType: 'Add',
    });
    toggleTransactionModal(true);
  };

  const editAction = (row: TransactionProps) => {
    setState({
      activeRow: row,
      modalType: 'Edit',
    });
    toggleTransactionModal(true);
  };

  const deleteAction = (row: TransactionProps) => {
    setState({
      activeRow: row,
      modalType: 'Delete',
    })
    toggleConfirmModal(true);
  };

  const handleConfirm = async (modalType: string) => {
    switch (modalType) {    
      case 'Edit':
        return handleEditConfirm();
      case 'Add':
        return handleAddConfirm();
      case 'Delete':
        return handleDeleteConfirm();
      default:
        return state;
    }
  }

  const handleAddConfirm = async () => {
    const { response } = await postTransactions(state.activeRow);
    if (response) {
      toggleTransactionModal(false);
      setState({ submitData: true });
    }
  }

  const handleEditConfirm = async () => {
    const { response } = await patchTransactions(state.activeRow);
    if (response) {
      toggleTransactionModal(false);
      setState({ submitData: true });
    }
  }

  const handleDeleteConfirm = async () => {
    const { response } = await deleteTransactions(state.activeRow);
    if (!response) {
      toggleConfirmModal(false);
      setState({ submitData: true });
    }
  }

  const getAccountData = async () => {
    const { response } = await getAccounts();
    const accountData: ListProps[] = [];
    if (response && response.results) {
      response.results.forEach(item => {
        accountData.push({
          value: (item.nickName || item.accountNumber || ''),
          id: (item.id || '').toString(),
        })
      });
    }
    setState({
      accountList: accountData,
    })
  };

  const getCategoryData = async () => {
    const { response } = await getCategories({});
    const expenseCategoryNames: ListProps[] = [];
    const incomeCategoryNames: ListProps[] = [];
    const transferCategoryNames: ListProps[] = [];
    if (response && response.results) {
      response.results.forEach(item => {
        if (item.baseTransactionType === 'EXPENSE') {
          expenseCategoryNames.push({
            value: item.name || '',
            id: (item.id || '').toString(),
          })
        } else if (item.baseTransactionType === 'INCOME') {
          incomeCategoryNames.push({
            value: item.name || '',
            id: (item.id || '').toString(),
          })
        } else if (item.baseTransactionType === 'TRANSFER') {
          transferCategoryNames.push({
            value: item.name || '',
            id: (item.id || '').toString(),
          })
        }
      });
    }
    setState({
      expenseCategoryList: expenseCategoryNames,
      incomeCategoryList: incomeCategoryNames,
      transferCategoryList: transferCategoryNames,
    })
  };

  const getTransactionData = useCallback( async () => {
    const { response } = await getTransactions({ filterDate });
    const categoryData: TransactionProps[] = [];
    if (response && response.results) {
      response.results.forEach(item => {
        categoryData.push(item);
      });
    }
    setFilterString('');
    expandedRow.current = {};
    setState({
      transactionData: categoryData,
      loadingData: false,
      submitData: false,
    });
  }, [filterDate]);

  const Table = ({ columns, data }: TableOptions<TransactionProps>) => {
    const {
      getTableProps,
      rows,
      prepareRow,
      isAllRowsExpanded,
      toggleAllRowsExpanded,
      state: {
        expanded,
      },
    } = useTable({
      columns,
      data,
      initialState: {
        globalFilter: filterString,
        groupBy: ['transactionDate'],
        expanded: expandedRow.current,
      },
      autoResetExpanded: false,
      autoResetGroupBy: false,
    },
    useGlobalFilter,
    useGroupBy,
    useExpanded,
    );

    expandedRow.current = expanded;
    
    if (rows.length > 0 && Object.keys(expandedRow.current).length === 0 && !isAllRowsExpanded && autoExpended.current) {
      toggleAllRowsExpanded(true);
    }

    return (
      <>
        <div
          {...getTableProps()}
          style={{
            width: '100%',
          }}
        >
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              // eslint-disable-next-line react/jsx-key
              <div {...row.getRowProps()}>
                <div className={styles.rowContainer}>
                  {row.isGrouped ? 
                    <div className={styles.groupedRowClass}>
                      <div className={styles.groupedCellClass}>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        >
                          <div>Date</div>
                          <div>{row.values.transactionDate}</div>
                        </div> 
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'right',
                        }}
                        >
                          <div>Credit</div>
                          <div>{row.values.credit}</div>
                        </div> 
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'right',
                        }}
                        >
                          <div>Debit</div>
                          <div>{row.values.debit}</div>
                        </div> 
                      </div>
                      {row.canExpand ? (
                        <span
                          {...row.getToggleRowExpandedProps()}
                        >
                          {row.isExpanded ? '👇' : '👉'}
                        </span>
                      ) : null}
                    </div>
                    :
                    <>
                      <div className={styles.rowClass}>
                        <div className={styles.cellClass}>
                          <div className={styles.cellGroupStyle}>
                            <div>
                              <Avatar size="50" round={true} name={row.values.categoryName} color="var(--blueberry)" />
                            </div>
                            <div>
                              {row.values.categoryName}
                            </div>
                          </div>
                          <div className={styles.cellGroupStyle}>
                            <div>
                              {row.values.description}
                            </div>
                            <div>
                              {row.values.note ? `${row.values.accountNickName} | ${row.values.note}` : row.values.accountNickName}
                            </div>
                          </div>
                          <div style={{
                            textAlign: 'right',
                          }}
                          >
                            {row.original.entryType === 'CREDIT' ? '- ' : ''}{row.values.transactionAmount}
                          </div>
                        </div>
                      </div>
                      <ActionMenu
                        id={`${row.original.id}`}
                        items={['Edit', 'Delete']} 
                        size='sm' 
                        handler={(act) => (act === 'Edit') ? editAction(row.original) : deleteAction(row.original)}
                      />
                    </>}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  const columns = React.useMemo(() => [{
    Header: 'None',
    columns: [
      {
        Header: 'transactionDate',
        accessor: (d: { transactionDate: moment.MomentInput; }) => moment(d.transactionDate).format('DD MMM YYYY'),
      },
      {
        Header: 'Category Name',
        accessor: 'categoryName',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Account Nickname',
        accessor: 'accountNickName',
      },
      {
        Header: 'Note',
        accessor: 'note',
      },
      {
        Header: 'Reference',
        accessor: 'referenceCode',
      },
      {
        Header: 'Transaction Amount',
        accessor: 'transactionAmount',
      },
      {
        Header: 'Credit',
        accessor: 'credit',
        aggregate: 'sum',
        Aggregated: ({ value }: { value: number }) => `${value} (total)`,
      },
      {
        Header: 'Debit',
        accessor: 'debit',
        aggregate: 'sum',
        Aggregated: ({ value }: { value: number }) => `${value} (total)`,
      },
    ],
  }], []);
  
  useEffect(() => {
    if (state.loadingData) {
      getCategoryData();
      getAccountData();
      getTransactionData();
    } else if (state.submitData) {
      getTransactionData();
    }
  }, [state.loadingData, state.submitData]);

  return (
    <div className={styles.container}>
      {state.loadingData ? (
        <p>Loading Please wait...</p>
      ) : (
        <>
          {!transactionModalState ? (
            <section className={styles.discoverMainSection}>
              <div className={styles.callToAction}>
                <DateDropdown
                  dateFormat="MMM yyyy"
                  isClearable={false}
                  placeholder="Date"
                  value=""
                  selected={filterDate}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => handleFilterDate(e)}
                  showMonthYearPicker
                />
                <TextField
                  label="Search"
                  value={filterString}
                  handler={e => handleFilterString(e.target.value)}
                />
                <Table columns={columns} data={state.transactionData} />
              </div>
            </section>
          ) : (
            <Modal active={transactionModalState}>
              <div className={`${styles.user__modal}`}>
                <div className={styles.user__modal__siteForm}>
                  <div className={styles.user__modal__siteForm__main}>
                    <ModalHeader heading={(state.modalType === 'Edit') ? 'Edit Transaction' : 'Add Transaction'} />
                    <ModalContent>
                      <TabSet
                        center
                        borderStyle="warm-grey-100"
                        activeTab={state.activeRow.baseTransactionType}
                        disabled={(state.modalType === 'Edit')} 
                        handleTabClick={tab => setActiveRow({
                          baseTransactionType: tab,
                          categoryId: undefined,
                          categoryName: undefined,
                          transferAccountId: undefined,
                          transferAccountNickName: undefined,
                          note: undefined,
                        })}
                      >
                        {state.tabs.map((tab: string) => (
                          <div key={tab} id={tab.toUpperCase()} title={tab}>
                            {tab === 'Expense' && (
                              <Expense
                                activeRow={state.activeRow}
                                setActiveRow={setActiveRow}
                                categoryList={state.expenseCategoryList}
                                accountList={state.accountList}
                              />
                            )}
                            {tab === 'Income' && (
                              <Income
                                activeRow={state.activeRow}
                                setActiveRow={setActiveRow}
                                categoryList={state.incomeCategoryList}
                                accountList={state.accountList}
                              />
                            )}
                            {tab === 'Transfer' && (
                              <Transfer
                                activeRow={state.activeRow}
                                setActiveRow={setActiveRow}
                                categoryList={state.transferCategoryList}
                                accountList={state.accountList}
                              />
                            )}
                          </div>
                        ))}
                      </TabSet>   
                    </ModalContent>
                  </div>
                </div>
                <footer className={styles.user__modal__footer}>
                  <ModalFooter>
                    <Button
                      btnStyle="secondary"
                      btnType="icon"
                      disabled={!state.activeRow.accountNickName}
                      handler={(state.modalType === 'Edit') ? handleEditConfirm : handleAddConfirm}
                    >
                      Confirm
                    </Button>
                    <Button btnType="icon" btnStyle="tertiary" handler={() => toggleTransactionModal(false)}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </footer>
              </div>
            </Modal>
          )}
          <ModalConfirm
            active={confirmModalState}
            text="Are you sure you want to delete this?"
            cancelButtonLabel="Cancel"
            confirmButtonLabel="Confirm"
            cancelHandler={() => toggleConfirmModal(false)}
            confirmHandler={() => handleConfirm(state.modalType)}
          />
          <div className="actionFloat">
            <Button
              btnStyle="primary"
              btnType="icon"
              elevation="32-8"
              handler={() => addAction()}
            >
              <Icon icon="Add" fill="white" size={32} />
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default Transactions
