import { getCategoryV1Data, patchCategoryV1Data, postCategoryV1Data, deleteCategoryV1Data } from 'services/categoryV1';
import { APIBaseProps, APIResponse } from 'types/global';

export interface CategoryProps extends APIBaseProps<CategoryProps> {
  id?: number,
  categoryCode?: string,
  name?: string,
  description?: string,
  categorySetId?: number,
  categorySetName?: string,
  categorySetDesc?: string,
  type?: string,
  entryType?: string,
  baseTransactionType?: string,
  icon?: string,
  startDate?: Date,
  endDate?: Date,
  custom?: string,
  status?: string,
}
export async function getSuggestedCategory(keyword: string, mappingType?: string, accountId?: number, transactionType?: string, transferAccountId?: number): Promise<APIResponse<CategoryProps>> {
  let response;
  let errorMessage = '';
  let queryString = `keyword=${keyword}`;
  queryString += mappingType ? `&mappingType=${mappingType}` : '';
  queryString += accountId ? `&accountId=${accountId}` : '';
  queryString += transactionType ? `&type=${transactionType}` : '';
  queryString += transferAccountId ? `&transferAccountId=${transferAccountId}` : '';
  try {
    response = await getCategoryV1Data({
      url: `categories/suggestion?${queryString}`,
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}

export async function getCategories({ categorySetId, baseTransactionType }: CategoryProps): Promise<APIResponse<CategoryProps>> {
  let response;
  let errorMessage = '';
  let queryString = '';
  queryString += categorySetId ? `&categorySetId=${categorySetId}` : '';
  queryString += baseTransactionType ? `&baseTransactionType=${baseTransactionType}` : '';
  try {
    response = await getCategoryV1Data({
      url: `categories?${queryString}`,
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}

export async function patchCategories(row: CategoryProps): Promise<APIResponse<CategoryProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await patchCategoryV1Data({
      url: `categories/${row.id}`,
      data: { name: row.name, description: row.description, status: row.status },
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}

export async function deleteCategories(row: CategoryProps): Promise<APIResponse<CategoryProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await deleteCategoryV1Data({
      url: `categories/${row.id}`,
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}

export async function postCategories(categorySetId: number, row: CategoryProps): Promise<APIResponse<CategoryProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await postCategoryV1Data({
      url: 'categories',
      data: { categorySetId, name: row.name, description: row.description, status: row.status },
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}
