import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Chart, BarGraph, Graph } from 'assets/Images/';

import { login } from 'services/authV1/login';
import { useHistory } from 'react-router-dom';
import { useAuthDispatch, useAuthState } from 'context/AuthContext';
import {
  useSetState,
  setSessionStorageItem,
  getSessionStorageItem,
} from 'utils';
// Types
import { APIResponse } from 'types/global';
// eslint-disable-next-line import/named
import { authoriseUserReturn, initialiseLogin } from 'context/AuthContext/middleware';
// Elements
import Button from 'elements/Button';
// Style
import styles from './home.module.css';
// eslint-disable-next-line import/order
import { getUser } from 'services/userV1/user';

export function Home() {
  const { user: {
    firstName,
  } } = useAuthState(); 
  const [showLogin, setShowLogin] = useState(false);
  const [logoutLoader, setLogoutLoader] = useState(false);
  const [logout, setLogout] = useState(false);
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const defaultState = {
    loadingData: true,
  };
  const [state, setState] = useSetState(defaultState);

  const handleLoginSession = useCallback(async () => {
    const authSession = getSessionStorageItem({ item: 'auth' });
    if (authSession) {
      authoriseUserReturn(dispatch);
    } else {
      await setShowLogin(true);
      // await initialiseLogin(state.email, state.password, dispatch);
      setLogoutLoader(true);
      setTimeout(() => {
        setTimeout(() => {
          setLogoutLoader(false);
        }, 400);
      }, 1500);
    }

    setState({
      loadingData: false,
    });
  }, [dispatch]);

  useEffect(() => {
    handleLoginSession();
  }, [handleLoginSession]);

  return (
    <>
      { state.loadingData ? (
        <p>Loading Please wait...</p>
      ) : (
        <section className={styles.discoverMainSection}>
          <div className={styles.callToAction}>
            <h1 className={styles.title}>Discover new music</h1>
            <div className={styles.iconSection}>
              <div className={styles.icon}>
                <img src={Chart} alt-text="abc" />
                Charts
              </div>
              <div className={styles.icon}>
                <img src={BarGraph} />
                Graphs
              </div>
              <div className={styles.icon}>
                <img src={Graph} />
                More
              </div>
            </div>
            By joining you can benefit by listening to the latest albums released.
            <Button btnType="icon" btnStyle={styles.link} href="content">content</Button>
            {firstName}
          </div>
          <div className={styles.coversImage} />
        </section>
      )}
    </>
  );
}