import React, { useEffect, useState } from 'react';
import { useHistory, NavLink, useRouteMatch } from 'react-router-dom';
import { useSetState, getSessionStorageItem, setSessionStorageItem, removeSessionStorageItem } from 'utils';
import TabSet from 'components/TabSet';
import { TableOptions, useTable } from 'react-table'
import { getCategory, postCategory, deleteCategory, patchCategory, CategorySetProps } from 'services/categoryV1/categorySets';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'elements/Modal';
import { ToggleItem } from 'elements/ListItems';
import Button from 'elements/Button';
import Icon from 'assets/Icon';
import { TextField, Select } from 'elements/Forms';
// Style
import ActionMenu from 'elements/ActionMenu';
import styles from '../CategoryDetail.module.css';

function CategorySet() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const defaultState = {
    loadingData: true,
    submitData: false,
    activeRow: {} as CategorySetProps,
    tabs: ['Expense', 'Income', 'Transfer'],
    selectedTab: getSessionStorageItem({ item: 'selectedTab' }) || 'Expense',
    expenseData: [] as CategorySetProps[],
    incomeData: [] as CategorySetProps[],
    transferData: [] as CategorySetProps[],
    modalType: '',
  };
  const [state, setState] = useSetState(defaultState);
  const [modalActive, setModalActive] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);

  const setRowId = (id?: number) => {
    setSessionStorageItem({ item: 'selectedId', value: id });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  const getRowId = (): number => getSessionStorageItem({ item: 'selectedId' });

  const toggleModal = (active: boolean) => {
    setModalActive(active);
  };

  const deleteToggleModal = (active: boolean) => {
    setDeleteModalActive(active);
  };
  
  function selectTab(tab: string) {
    history.push(path);
    setState({
      selectedTab: tab,
    });
    setSessionStorageItem({ item: 'selectedTab', value: tab });
    removeSessionStorageItem({ item: 'selectedId' });
  }

  const addCategorySet = () => {
    setState({
      activeRow: {},
      modalType: 'Add',
    });
    toggleModal(true);
  };

  const editCategorySet = (row: CategorySetProps) => {
    setRowId(row.id);
    setState({
      activeRow: row,
      modalType: 'Edit',
    });
    toggleModal(true);
  };

  const deleteCategorySet = (row: CategorySetProps) => {
    setState({
      activeRow: row,
      modalType: 'Delete',
    })
    deleteToggleModal(true);
  };

  const handleConfirm = async (modalType: string) => {
    switch (modalType) {    
      case 'Edit':
        return handleEditConfirm();
  
      case 'Add':
        return handleAddConfirm();
  
      case 'Delete':
        return handleDeleteConfirm();
  
      default:
        return state;
    }
  }

  const handleAddConfirm = async () => {
    const { response } = await postCategory(state.activeRow);
    if (response) {
      toggleModal(false);
      setState({ submitData: true });
    }
  }

  const handleEditConfirm = async () => {
    const { response } = await patchCategory(state.activeRow);
    if (response) {
      toggleModal(false);
      setState({ submitData: true });
    }
  }

  const handleDeleteConfirm = async () => {
    const { response } = await deleteCategory(state.activeRow);
    if (!response) {
      deleteToggleModal(false);
      setState({ submitData: true });
    }
  }

  const isItemActive = (rowId?: number) => getRowId() === rowId;

  const setStatus = () => {
    const currStatus = state.activeRow.status === 'Y';
    setState({ 
      activeRow: { ...state.activeRow, 
        status: currStatus ? 'N' : 'Y' }, 
    });
  }

  const formatTabData = (data: CategorySetProps[]) => {
    if (data){
      const tempExpense: CategorySetProps[] = [];
      const tempIncome: CategorySetProps[] = [];
      const tempTransfer: CategorySetProps[] = [];
      data.forEach((item: CategorySetProps) => {
        if (item.type === 'EXPENSE') {
          tempExpense.push( item );
        } else if (item.type === 'INCOME') {
          tempIncome.push( item );
        } else {
          tempTransfer.push( item );
        }
      });
      setState({
        expenseData: tempExpense,
        incomeData: tempIncome,
        transferData: tempTransfer,
      });
    }
    setState({
      loadingData: false,
      submitData: false,
    });
  }

  const getCategorySet = async () => {
    const { response } = await getCategory();
    const categoryData: CategorySetProps[] = [];
    if (response && response.results) {
      response.results.forEach(item => {
        categoryData.push(item);
      });
    }
    formatTabData(categoryData);
  }

  const Table = ({ columns, data }: TableOptions<CategorySetProps>) => {
    const { getTableProps, rows, prepareRow } = useTable({
      columns,
      data,
    });

    return (
      <>
        <div
          {...getTableProps()}
        >
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <div key={row.original.id} className={styles.rowContainer}>
                <NavLink
                  exact
                  to={`${path}/detail`}
                  onClick={() => setRowId(row.original.id)}
                  className={styles.component}
                  activeClassName={styles.active}
                  isActive={() => isItemActive(row.original.id)}
                >
                  <div
                    className={styles.rowClass}
                    {...row.getRowProps()}
                  >
                    <div>
                      {row.cells.map(cell => (
                        <div
                          {...cell.getCellProps()}
                          key={cell.column.id}
                          style={{
                            color: 'white',
                            fontWeight: 'bold',
                          }}
                        >
                          {cell.render('Cell')}
                        </div>
                      ))}
                    </div>
                  </div>
                </NavLink>
                <ActionMenu
                  id={`${row.original.id}`}
                  items={['Edit', 'Delete']} 
                  size='sm' 
                  handler={(act) => (act === 'Edit') ? editCategorySet(row.original) : deleteCategorySet(row.original)} 
                />
              </div>
            );
          })}
        </div>
      </>
    );
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Description',
            accessor: 'description',
          },
        ],
      },
    ],
    [],
  );
  
  useEffect(() => {
    if (state.loadingData || state.submitData) {
      getCategorySet();
    }
  }, [state.loadingData, state.submitData]);

  return (
    <div className={styles.container}>
      {state.loadingData ? (
        <p>Loading Please wait...</p>
      ) : (
        <section className={styles.discoverMainSection}>
          <div className={styles.callToAction}>
            <TabSet
              borderStyle="warm-grey-100"
              handleTabClick={selectTab}
              activeTab={state.selectedTab}
              addButton={
                <Button
                  btnStyle="tertiary"
                  btnType="icon"
                  elevation="32-8"
                  handler={() => addCategorySet()}
                >
                  <Icon icon="Add" fill="white" size={32} />
                </Button>
            }
            >
              {state.tabs.map((tab: string) => (
                <div key={tab} id={tab}>
                  { tab === 'Expense' && (
                    <Table columns={columns} data={state.expenseData} />
                  )}
                  { tab === 'Income' && (
                    <Table columns={columns} data={state.incomeData} />
                  )}
                  { tab === 'Transfer' && (
                    <Table columns={columns} data={state.transferData} />
                  )}
                </div>
              ))}
            </TabSet>
            <Modal active={modalActive}>
              <div className={`${styles.user__modal}`}>
                <div className={styles.user__modal__siteForm}>
                  <div className={styles.user__modal__siteForm__main}>
                    <ModalHeader heading={(state.modalType === 'Edit') ? 'Edit Category' : 'Add Category'} />
                    <ModalContent>
                      <TextField
                        label="Name"
                        value={state.activeRow.name}
                        handler={e => setState({ activeRow: { ...state.activeRow, name: e.target.value } })}
                      />
                      <TextField
                        label="Description"
                        value={state.activeRow.description}
                        handler={e => setState({ activeRow: { ...state.activeRow, description: e.target.value } })}
                      />
                      <ToggleItem
                        checked={state.activeRow.status === 'Y'}
                        name="status-toggle"
                        handler={() => setStatus()}
                        label="Status"
                      />    
                      <Select
                        disabled={(state.modalType === 'Edit')}
                        handleOnChange={val => setState({ activeRow: { ...state.activeRow, type: val } })}
                        items={state.tabs}
                        label="Select Type"
                        name="type-select"
                        value={state.activeRow.type || state.selectedTab}
                      />
                        
                    </ModalContent>
                  </div>
                </div>
                <footer className={styles.user__modal__footer}>
                  <ModalFooter>
                    <Button
                      btnStyle="secondary"
                      btnType="icon"
                      disabled={!state.activeRow.name}
                      handler={(state.modalType === 'Edit') ? handleEditConfirm : handleAddConfirm}
                    >
                      Confirm
                    </Button>
                    <Button btnType="icon" btnStyle="tertiary" handler={() => toggleModal(false)}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </footer>
              </div>
            </Modal>
            <Modal active={deleteModalActive}>
              <ModalContent>
                <p>Are you sure you want to delete this?</p>
              </ModalContent>
              <ModalFooter>
                <Button
                  btnStyle="tertiary"
                  handler={() => deleteToggleModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  btnStyle="secondary"
                  handler={() => handleConfirm(state.modalType)}
                >
                  Confirm
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </section>
      )}
    </div>
  );
}

export default CategorySet
