/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import moment from 'moment';
import { validArray } from 'utils';
import DateDropdown from 'elements/DateDropdown';
import { TextField, SelectWithId } from 'elements/Forms';
import { ToggleItem } from 'elements/ListItems';
import { getSuggestedCategory } from 'services/categoryV1/categories';
import { TransactionProps } from 'services/accountV1/transactions';
// Style
import { ListProps } from 'types/global';
import styles from '../Transactions.module.css';
import { ModelProps, suggestDescription } from './Expense';

function Transfer({ activeRow, setActiveRow, categoryList, accountList }:ModelProps) {
  return (
    <>
      <div className={styles.grid}>
        <SelectWithId
          handleOnChange={(value, id) => setActiveRow({ accountNickName: value, accountId: id })}
          items={validArray(accountList) ? accountList : []}
          label="Account Name"
          name="account-name"
          value={activeRow.accountNickName}
          valueId={`${activeRow.accountId}`}
        />
        <DateDropdown
          dateFormat="dd MMM yyyy"
          isClearable={false}
          placeholder="Date"
          value=""
          selected={activeRow.transactionDate ? moment(activeRow.transactionDate).toDate() : null}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: any) => setActiveRow({ transactionDate: e })}
        />
      </div>
      <div className={styles.grid}>
        <SelectWithId
          handleOnChange={(value, id) => setActiveRow({ transferAccountNickName: value, transferAccountId: id })}
          items={validArray(accountList) ? accountList : []}
          label="Transfer Account"
          name="transfer-account"
          value={activeRow.transferAccountNickName}
          valueId={`${activeRow.transferAccountId}`}
        />
        <TextField
          label="Description"
          value={activeRow.description}
          handler={e => setActiveRow({ description: e.target.value })}
          handleBlur={e => suggestDescription(activeRow, setActiveRow)}
        />
      </div>
      <div className={styles.grid}>
        <TextField
          label="Amount"
          value={activeRow.transactionAmount}
          handler={e => setActiveRow({ transactionAmount: Number(e.target.value) })}
        />
        <SelectWithId
          handleOnChange={(value, id) => setActiveRow({ categoryName: value, categoryId: id })}
          items={validArray(categoryList) ? categoryList : []}
          label="Category Name"
          name="category-name"
          value={activeRow.categoryName}
          valueId={`${activeRow.categoryId}`}
        />
      </div>
    </>
  )
}

export default Transfer;