import { AppContextProps } from 'types/global';
import * as actionType from './actionTypes';

export function setAuthState(state: string) {
  return {
    type: actionType.SET_AUTH_STATE,
    payload: state,
  }
}

export function authUser(state: string) {
  return {
    type: actionType.AUTHORISE_USER,
    payload: state,
  }
}

export function setUser(user: AppContextProps['user']) {
  return {
    type: actionType.SET_USER,
    payload: user,
  }
}

export function setToken(token: AppContextProps['v2tkn']) {
  return {
    type: actionType.SET_TOKEN,
    payload: token,
  }
}

export function unauthUser() {
  return {
    type: actionType.UNAUTHORISE_USER,
  }
}

export function setError(error: string) {
  return {
    type: actionType.SET_ERROR,
    payload: error,
  }
}

export function authorizeReturnUser(token: string) {
  return {
    type: actionType.AUTHORIZE_USER_SUCCESS,
    payload: token,
  }
}
