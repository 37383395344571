/* eslint-disable max-len */
const icons = {
  Add: 'M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z',
  Adjustments: 'M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z',
  Apps: 'M6 16a2 2 0 110 4 2 2 0 010-4zm6 0a2 2 0 110 4 2 2 0 010-4zm6 0a2 2 0 110 4 2 2 0 010-4zM6 10a2 2 0 110 4 2 2 0 010-4zm6 0a2 2 0 110 4 2 2 0 010-4zm6 0a2 2 0 110 4 2 2 0 010-4zM6 4a2 2 0 110 4 2 2 0 010-4zm6 0a2 2 0 110 4 2 2 0 010-4zm6 0a2 2 0 110 4 2 2 0 010-4z',
  ArrowBack: 'M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 00-1.41 0l-6.59 6.59a.996.996 0 000 1.41l6.59 6.59a.996.996 0 101.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1z',
  ArrowRight: 'M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z',
  Bin: 'M6 18.5A1.5 1.5 0 007.5 20h9a1.5 1.5 0 001.5-1.5V8H6v10.5zm8.5-8a.5.5 0 111 0v7a.5.5 0 01-1 0v-7zm-3 0a.5.5 0 111 0v7a.5.5 0 01-1 0v-7zm-3 0a.5.5 0 111 0v7a.5.5 0 01-1 0v-7zm10-5.5h-3.75l-.294-.584A.75.75 0 0013.784 4h-3.572a.741.741 0 00-.668.416L9.25 5H5.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z',
  Calendar: 'M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1',
  Checkbox: 'M18 19H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1zm1-16H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z',
  CheckboxActive: 'M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-8.29 13.29a.996.996 0 01-1.41 0L5.71 12.7a.996.996 0 111.41-1.41L10 14.17l6.88-6.88a.996.996 0 111.41 1.41l-7.58 7.59z',
  ChevronRight: 'M9.29 6.71a.996.996 0 000 1.41L13.17 12l-3.88 3.88a.996.996 0 101.41 1.41l4.59-4.59a.996.996 0 000-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z',
  Close: 'M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z',
  Duplicate: 'M3 6c-.55 0-1 .45-1 1v13c0 1.1.9 2 2 2h13c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1-.45-1-1V7c0-.55-.45-1-1-1zm17-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 9h-3v3c0 .55-.45 1-1 1s-1-.45-1-1v-3h-3c-.55 0-1-.45-1-1s.45-1 1-1h3V6c0-.55.45-1 1-1s1 .45 1 1v3h3c.55 0 1 .45 1 1s-.45 1-1 1z',
  Edit: 'M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z',
  Error: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z',
  Exceptions: 'M18 14c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm2.1-11c.37 0 .824.34.891.787L21 3.9l.001 10.1a4.977 4.977 0 00-2-.9L19 5H5v14l8.1.001c.15.74.464 1.419.9 2L3.9 21c-.462 0-.838-.426-.893-.806L3 20.1V3.9c0-.462.34-.838.787-.893L3.9 3h16.2zm-1.7 16.2h-.8v.8h.8v-.8zM18 16c-.22 0-.4.18-.4.4V18c0 .22.18.4.4.4.22 0 .4-.18.4-.4v-1.6c0-.22-.18-.4-.4-.4zm-9-1v2H7v-2h2zm8-4v2h-6v-2h6zm-8 0v2H7v-2h2zm8-4v2h-6V7h6zM9 7v2H7V7h2z',
  ExpandMore: 'M15.88 9.29L12 13.17 8.12 9.29a.996.996 0 10-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 000-1.41c-.39-.38-1.03-.39-1.42 0z',
  Export: 'M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2v9.67z',
  Flight: 'M22 16v-2l-8.5-5V3.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5V9L2 14v2l8.5-2.5V19L8 20.5V22l4-1 4 1v-1.5L13.5 19v-5.5L22 16z',
  Info: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z',
  Issue: 'M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2zm-6 3l-.881.881 3.487 3.494H9v1.25h7.606l-3.487 3.494L14 15l5-5-5-5z',
  Loading: '',
  More: 'M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z',
  MoreAlt: 'M14 6c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2zm0 12c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2zm0-6c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2z',
  Notification: 'M12 19.333c.733 0 1.327-.593 1.327-1.326h-2.654c0 .733.594 1.326 1.327 1.326zm5.527-3.14l-.86-.86v-4c0-2.233-1.574-4.1-3.667-4.553V6c0-.553-.447-1-1-1-.553 0-1 .447-1 1v.78a4.662 4.662 0 00-3.667 4.553v4l-.86.86c-.42.42-.126 1.14.467 1.14h10.113c.6 0 .894-.72.474-1.14z',
  NumberList: 'M8 7h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm12 10H8c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1zm0-6H8c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1zM4.5 16h-2c-.28 0-.5.22-.5.5s.22.5.5.5H4v.5h-.5c-.28 0-.5.22-.5.5s.22.5.5.5H4v.5H2.5c-.28 0-.5.22-.5.5s.22.5.5.5h2c.28 0 .5-.22.5-.5v-3c0-.28-.22-.5-.5-.5zm-2-11H3v2.5c0 .28.22.5.5.5s.5-.22.5-.5v-3c0-.28-.22-.5-.5-.5h-1c-.28 0-.5.22-.5.5s.22.5.5.5zm2 5h-2c-.28 0-.5.22-.5.5s.22.5.5.5h1.3l-1.68 1.96a.49.49 0 00-.12.32v.22c0 .28.22.5.5.5h2c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H3.2l1.68-1.96a.49.49 0 00.12-.32v-.22c0-.28-.22-.5-.5-.5z',
  Print: 'M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z',
  RadioActive: 'M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z',
  RadioEmpty: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z',
  Receipt: 'M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2zm-6 3l-5 5 5 5 .881-.881-3.487-3.494H19v-1.25h-7.606l3.487-3.494L14 5z',
  Scan: 'M9.975 8.6h-.45a.226.226 0 00-.225.225v6.75c0 .124.101.225.225.225h.45a.226.226 0 00.225-.225v-6.75a.226.226 0 00-.225-.225zm-1.8 0h-1.35a.226.226 0 00-.225.225v6.75c0 .124.101.225.225.225h1.35a.226.226 0 00.225-.225v-6.75a.226.226 0 00-.225-.225zM7.05 18.05h-2.7v-2.7a.451.451 0 00-.45-.45h-.45a.451.451 0 00-.45.45v3.6c0 .248.203.45.45.45h3.6c.248 0 .45-.202.45-.45v-.45a.451.451 0 00-.45-.45zM3.45 9.5h.45c.248 0 .45-.203.45-.45v-2.7h2.7c.248 0 .45-.202.45-.45v-.45A.451.451 0 007.05 5h-3.6a.451.451 0 00-.45.45v3.6c0 .247.203.45.45.45zm13.95 6.075v-6.75a.226.226 0 00-.225-.225h-1.35a.226.226 0 00-.225.225v6.75c0 .124.101.225.225.225h1.35a.226.226 0 00.225-.225zM11.775 8.6h-.45a.226.226 0 00-.225.225v6.75c0 .124.101.225.225.225h.45a.226.226 0 00.225-.225v-6.75a.226.226 0 00-.225-.225zm8.775 6.3h-.45a.451.451 0 00-.45.45v2.7h-2.7a.451.451 0 00-.45.45v.45c0 .248.203.45.45.45h3.6c.247 0 .45-.202.45-.45v-3.6a.451.451 0 00-.45-.45zm0-9.9h-3.6a.451.451 0 00-.45.45v.45c0 .247.203.45.45.45h2.7v2.7c0 .247.203.45.45.45h.45c.247 0 .45-.203.45-.45v-3.6a.451.451 0 00-.45-.45zm-6.075 3.6h-1.35a.226.226 0 00-.225.225v6.75c0 .124.101.225.225.225h1.35a.226.226 0 00.225-.225v-6.75a.226.226 0 00-.225-.225z',
  Search: 'M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z',
  Settings: 'M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58a.49.49 0 00.12-.61l-1.92-3.32a.488.488 0 00-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54a.484.484 0 00-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94 0 .31.02.64.07.94l-2.03 1.58a.49.49 0 00-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6A3.61 3.61 0 018.4 12c0-1.98 1.62-3.6 3.6-3.6s3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z',
  Sites: 'M20,3 L12,3 C11.45,3 11,3.45 11,4 L11,5.61 C11,5.61 11,5.61 11.01,5.62 L16.01,10.12 C16.64,10.68 17,11.5 17,12.35 L17,13 L19,13 L19,15 L17,15 L17,17 L19,17 L19,19 L17,19 L17,21 L20,21 C20.55,21 21,20.55 21,20 L21,4 C21,3.45 20.55,3 20,3 Z M15,7 L13,7 L13,5 L15,5 L15,7 Z M19,11 L17,11 L17,9 L19,9 L19,11 Z M9,6.85 C9.24,6.85 9.48,6.93 9.67,7.11 L9.67,7.11 L14.67,11.61 C14.88,11.8 15,12.07 15,12.35 L15,12.35 L15,20 C15,20.55 14.55,21 14,21 L14,21 L12,21 C11.45,21 11,20.55 11,20 L11,20 L11,16 L7,16 L7,20 C7,20.55 6.55,21 6,21 L6,21 L4,21 C3.45,21 3,20.55 3,20 L3,20 L3,12.35 C3,12.06 3.12,11.79 3.33,11.61 L3.33,11.61 L8.33,7.11 C8.52,6.94 8.76,6.85 9,6.85 Z M19,5 L19,7 L17,7 L17,5 L19,5 Z',
  Split: 'M20 14l-2.29 2.29-2.88-2.88-1.42 1.42 2.88 2.88L14 20h6v-6zm0-4V4h-6l2.29 2.29-4.7 4.71H4v2h8.41l5.3-5.29L20 10z',
  Subtract: 'M18 13H6c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1s-.45 1-1 1z',
  Template: 'M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4l6 6v10c0 1.1-.9 2-2 2H7.99C6.89 23 6 22.1 6 21l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L14 6.5V12z',
  Tick: 'M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z',
  Timeline: 'M23 8c0 1.1-.9 2-2 2a1.7 1.7 0 01-.51-.07l-3.56 3.55c.05.16.07.34.07.52 0 1.1-.9 2-2 2s-2-.9-2-2c0-.18.02-.36.07-.52l-2.55-2.55c-.16.05-.34.07-.52.07s-.36-.02-.52-.07l-4.55 4.56c.05.16.07.33.07.51 0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2c.18 0 .35.02.51.07l4.56-4.55C8.02 9.36 8 9.18 8 9c0-1.1.9-2 2-2s2 .9 2 2c0 .18-.02.36-.07.52l2.55 2.55c.16-.05.34-.07.52-.07s.36.02.52.07l3.55-3.56A1.7 1.7 0 0119 8c0-1.1.9-2 2-2s2 .9 2 2z',
  Transfer: 'M3 13h2v-2H3v2zm0 4h2v-2H3v2zm2 4v-2H3a2 2 0 002 2zM3 9h2V7H3v2zm12 12h2v-2h-2v2zm4-18H9a2 2 0 00-2 2v10a2 2 0 002 2h10c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 12H9V5h10v10zm-8 6h2v-2h-2v2zm-4 0h2v-2H7v2z',
  User: 'M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z',
  Users: 'M16.5 12c1.38 0 2.49-1.12 2.49-2.5S17.88 7 16.5 7a2.5 2.5 0 000 5zM9 11c1.66 0 2.99-1.34 2.99-3S10.66 5 9 5C7.34 5 6 6.34 6 8s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13z',
  Warning: 'M4.89 20h14.22c1.454 0 2.36-1.571 1.634-2.822l-7.11-12.24c-.728-1.25-2.54-1.25-3.268 0l-7.11 12.24C2.53 18.428 3.436 20 4.89 20zM12 13.415a.945.945 0 01-.944-.941v-1.882a.945.945 0 011.888 0v1.882c0 .517-.425.94-.944.94zm.944 3.763h-1.888v-1.882h1.888v1.882z',
};

export default icons;
