/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { Dispatch, useReducer } from 'react';

interface SessionStorageProps {
  item: string,
  value?: any,
}

export * from './media-queries.model';

export function useSetState<S>(initialState: S | (() => S)): [S, Dispatch<Partial<S>>] {
  function reducer(state: typeof initialState, newState: any) {
    return ({ ...state, ...newState });
  }
  const [state, setState] = useReducer(reducer, initialState);
  return [state, setState];
}

export function setSessionStorageItem({ item, value }: SessionStorageProps) {
  try {
    const serializedState = JSON.stringify(value);
    sessionStorage.setItem(item, serializedState);
  } catch (err) {
    console.error(`Could not set ${item}`);
  }
}

export const getSessionStorageItem = ({ item }: SessionStorageProps) => {
  try {
    const serializedState = sessionStorage.getItem(item);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error(`Could not get ${item}`);
    return undefined;
  }
};

export function removeSessionStorageItem({ item }: SessionStorageProps) {
  try {
    sessionStorage.removeItem(item);
  } catch (err) {
    console.error(`Could not remove ${item}`);
  }
}

export function preventDuplicate(arr: any[], label: any) {
  function filterArr(item: any) {
    return item.label !== label;
  }
  return arr.filter(filterArr);
}

export function removeById(arr: any[], id: any) {
  function filterArr(item: any) {
    return item.id !== id;
  }
  return arr.filter(filterArr);
}

export function delay(duration: number | undefined) {
  return new Promise(resolve => setTimeout(resolve, duration));
}

export const isEmptyObject = (obj: any) => obj && Object.entries(obj).length === 0 && obj.constructor === Object;

export function validArray(arr: any): boolean {
  return arr && arr.length > 0;
}
