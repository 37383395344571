import React, { useEffect, useRef, useState } from 'react';
import { validArray } from 'utils';
import { CheckItem } from 'elements/ListItems';
import styles from './style/select.module.css';
import SelectToggle from './SelectToggle';

interface SelectProps {
  name: string,
  label: string,
  items: string[] | boolean,
  value?: string | boolean,
  disabled?: boolean,
  handleOnChange: (val: string) => void,
}

const Select = ({
  disabled, handleOnChange, items, label, name, value,
}: SelectProps) => {
  const [open, setOpen] = useState(false);
  const select = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (select.current && select.current.contains(e.target as Node)) {
      return;
    }
    setOpen(false);
  };

  const toggleOpen = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <div
      className={`
        ${styles.select}
        ${open ? styles.select_open : ''}
        ${value ? styles.select_hasValue : ''}
        ${disabled ? styles.select_disabled : ''}
      `}
      onClick={toggleOpen}
      onKeyDown={toggleOpen}
      ref={select}
      role="button"
      tabIndex={0}
    >
      <SelectToggle
        active={open}
        disabled={disabled}
        label={label}
        value={value}
      />
      <div className={styles.select__options}>
        {validArray(items) && (items as string[]).map(item => (
          <CheckItem
            borderInset={1}
            checked={value === item}
            handler={e => handleOnChange((e.target as HTMLButtonElement).value)}
            key={item}
            label={item && item.replace(/_|-/g, ' ')}
            name={name}
            value={item}
          />
        ))}
      </div>
    </div>
  );
};

Select.defaultProps = {
  disabled: false,
  value: false,
};

export default Select;
