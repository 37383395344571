import React, { useEffect, useState } from 'react';
import Tab from 'elements/Tabs/Tab';
import styles from './style/tabSet.module.css';

interface TabSetProps {
  activeTab?: string,
  borderStyle?: string,
  center?: boolean,
  children: {
    props: {
      id: string,
      title: string,
      children: React.ReactChild,
    }
  }[],
  disabled?: boolean,
  handleTabClick?: (tab: string) => void,
  addButton?: React.ReactNode,
}

function TabSet({ activeTab, borderStyle, center, children, disabled, handleTabClick, addButton }:TabSetProps) {
  // State
  const [tab, setTab] = useState(activeTab || children[0].props.id);

  // Functions
  function handleClick(targetTab:string) {
    setTab(targetTab);
    if (handleTabClick) {
      handleTabClick(targetTab);
    }
  }

  useEffect(() => {
    setTab(activeTab || children[0].props.id);
  }, [activeTab, children]);

  return (
    <div className={styles.tabSet}>
      <div className={`${styles.tabSet__tabs} ${center && styles.tabSet__tabs_center}`}>
        <div>
          <ul>
            {children.map((child) => {
              const { id, title } = child.props;
              return (
                <li key={id}>
                  <Tab active={tab === id} disabled={disabled} label={title} value={id} handler={handleClick} />
                </li>
              );
            })}
          </ul>
          {borderStyle && (
            <div className={`${styles.tabSet__tabs__border} ${styles[borderStyle]}`} />
          )}
        </div>
        <div>{addButton}</div>
      </div>
      <div className={styles.tabs__content}>
        {children.map((child) => {
          if (child.props.id !== tab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
}

TabSet.defaultProps = {
  activeTab: '',
  borderStyle: '',
  center: false,
  disabled: false,
  handleTabClick: null,
  addButton: null,
};

export default TabSet;
