import React from 'react';
import DatePicker from 'react-datepicker';
import { DateField } from 'elements/Forms';
import 'react-datepicker/dist/react-datepicker.css';
import './style/datepicker.css';

const DateDropdown = ({
  placeholder,
  value,
  ...rest
}) => {
  const ref = React.createRef();
  return (
    <DatePicker
      customInput={(
        <DateField
          placeHolder={placeholder}
          ref={ref}
        />
      )}
      isClearable={Boolean(value)}
      placeholderText={placeholder}
      {...rest}
    />
  );
};

DateDropdown.defaultProps = {
  value: '',
};

export default DateDropdown;
