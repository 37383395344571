import React from 'react';
import styles from './style/modalHeader.module.css';

interface ModalHeaderProps {
  heading: string,
}

const ModalHeader = ({ heading }: ModalHeaderProps) => (
  <div className={styles.modalHeader}>
    <h3 className="display6">{ heading }</h3>
  </div>
);

export default ModalHeader;
