import axios from 'axios';
import config from 'config';
import { createApi } from 'utils/api';

const authV1 = axios.create({
  baseURL: config.authV1ApiBaseUrl,
});

const createAuthV1Api = createApi(authV1);
export const getAuthV1Data = createAuthV1Api('get');
export const patchAuthV1Data = createAuthV1Api('patch');
export const postAuthV1Data = createAuthV1Api('post');
export const putAuthV1Data = createAuthV1Api('put');
