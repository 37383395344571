import React from 'react';
import Button from 'elements/Button';
import { Modal, ModalContent, ModalFooter } from '.';

interface ModalConfirmProps {
  active?: boolean,
  text?: string,
  cancelButtonLabel?: string,
  confirmButtonLabel?: string,
  cancelHandler?: React.MouseEventHandler<HTMLButtonElement>,
  confirmHandler?: React.MouseEventHandler<HTMLButtonElement>,
}

const ModalConfirm = ({
  active,
  text,
  cancelButtonLabel,
  confirmButtonLabel,
  cancelHandler,
  confirmHandler,
}: ModalConfirmProps) => (
  <Modal active={active}>
    <ModalContent>
      <p>{text}</p>
    </ModalContent>
    <ModalFooter>
      <Button
        btnStyle="tertiary"
        handler={cancelHandler}
      >
        {cancelButtonLabel}
      </Button>
      <Button
        btnStyle="secondary"
        handler={confirmHandler}
      >
        {confirmButtonLabel}
      </Button>
    </ModalFooter>
  </Modal>
);

ModalConfirm.defaultProps = {
  active: false,
  text: '',
  cancelButtonLabel: '',
  confirmButtonLabel: '',
  cancelHandler: null,
  confirmHandler: null,
};

export default ModalConfirm;
