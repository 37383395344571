export function timeFormatter(startDate: Date, endDate: Date) {
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const timeDifference = Number(endDate) - Number(startDate);

  if (timeDifference < 0) {
    return null;
  }

  const minutes = Math.floor((timeDifference % hour) / minute);
  const seconds = Math.floor((timeDifference % minute) / second);

  const formatter = [];
  if (minutes > 0) {
    formatter.push(`${+minutes}:`);
  }
  if (seconds < 10) {
    formatter.push(`0${seconds}`);
  } else {
    formatter.push(`${seconds}`);
  }

  return formatter.join('');
}
