import React from 'react';
import Icon from 'assets/Icon';
import styles from './style/selectToggle.module.css';

interface SelectToggleProps {
  active?: boolean,
  disabled?: boolean,
  handler?: Function,
  label?: string,
  value?: boolean | string | string[],
}

const SelectToggle = ({
  active,
  disabled,
  handler,
  label,
  value,
}: SelectToggleProps) => (
  <div
    className={`
      ${styles.selectToggle} 
      ${active ? styles.selectToggle_active : ''} 
      ${disabled ? styles.selectToggle_disabled : ''} 
      ${value ? styles.selectToggle_hasValue : ''}
    `}
    onClick={handler ? () => handler() : () => null}
    onKeyDown={handler ? () => handler() : () => null}
    role="button"
    tabIndex={0}
  >
    <div className={styles.selectToggle__label}>
      { label }
    </div>
    <div className={styles.selectToggle__value}>
      { value }
    </div>
    <Icon icon="ExpandMore" fill={disabled ? 'body-100' : 'black'} />
  </div>
);

SelectToggle.defaultProps = {
  active: false,
  disabled: false,
  handler: null,
  label: '',
  value: false,
};

export default SelectToggle;
