import React from 'react';
import styles from './style/modalFooter.module.css';

interface ModalFooterProps {
  children: React.ReactNode,
}

const ModalFooter = ({ children }: ModalFooterProps) => (
  <div className={styles.modalFooter}>
    { children }
  </div>
);

export default ModalFooter;
