import React, { forwardRef } from 'react';
import styles from './style/textField.module.css';

const DateField = forwardRef(({
  error,
  disabled,
  onClick,
  placeHolder,
  value,
}, ref) => (
  <div className={`
    ${styles.textField} 
    ${disabled ? styles.textField_disabled : ''} 
    ${value ? styles.textField_hasValue : ''}`}
  >
    <input
      autoComplete="off"
      disabled={disabled}
      error={error}
      onChange={onClick}
      onClick={onClick}
      onFocus={onClick}
      onMouseDown={onClick}
      readOnly
      ref={ref}
      required
      type="text"
      value={value}
      data-testid="date-picker"
    />
    <div className={styles.textField__label}>{placeHolder}</div>
  </div>
));

DateField.defaultProps = {
  disabled: false,
  error: null,
  onClick: () => { },
  placeHolder: 'Placeholder Text',
  value: '',
};

export default DateField;
