import React from 'react';
import styles from './style/toggle.module.css';

interface ToggleProps {
  active?: boolean,
}

const Toggle = ({ active }: ToggleProps) => (
  <div className={`${styles.toggle} ${active ? styles.toggle_active : ''}`}>
    <div className={styles.toggle__ball} />
  </div>
);

Toggle.defaultProps = {
  active: false,
};

export default Toggle;
