import React from 'react';
import { AuthContext } from 'context/AuthContext';
import AppRoot from '../AppRoot';

function App() {
  return (
    <AuthContext>
      <AppRoot />
    </AuthContext>
  );
}

export default App;
