import moment from 'moment';
import { getAccountV1Data, patchAccountV1Data, postAccountV1Data, deleteAccountV1Data } from 'services/accountV1';
import { APIBaseProps, APIResponse } from 'types/global';

export interface TransactionProps extends APIBaseProps<TransactionProps> {
  id?: number,
  bookId?: number,
  bookName?: string,
  accountId?: number,
  accountNumber?: string,
  accountNickName?: string,
  accountType?: string,
  currency?: string,
  remainingAmount?: number,
  type?: string,
  entryType?: string,
  baseTransactionType?: string,
  refund?: string,
  transactionDate?: Date,
  valueDate?: Date,
  transactionMonth?: string,
  description?: string,
  debit?: number,
  credit?: number,
  transactionAmount?: number
  balance?: number,
  referenceCode?: string,
  note?: string,
  status?: string,
  marked?: string,
  tag?: string,
  categoryId?: number,
  categoryName?: string,
  categorySetName?: string,
  categoryIcon?: string,
  transferAccountId?: number,
  transferAccountNumber?: string,
  transferAccountNickName?: string,
  linkToTransactionId?: number,
  sequenceNumber?: number,
}

export async function getTransactionType(baseTransactionType: string, refund?: string) {
  let type = '';
  if (baseTransactionType === 'EXPENSE') {
    type = refund === 'Y' ? 'EXPENSE_CREDIT' : 'EXPENSE';
  } else if (baseTransactionType === 'INCOME') {
    type = refund === 'Y' ? 'INCOME_DEBIT' : 'INCOME';
  } else if (baseTransactionType === 'TRANSFER') {
    type = refund === 'Y' ? 'TRANSFER_CREDIT' : 'TRANSFER';
  }
  return type;
}

export async function getTransactions({ filterDate, filterUnit } : { filterDate?: moment.MomentInput, filterUnit?: moment.unitOfTime.StartOf } ): Promise<APIResponse<TransactionProps>> {
  let response;
  let errorMessage = '';
  let queryString = 'sortBy=transactionDate:desc,sequenceNumber:desc';
  
  if (filterDate) {
    const dateFrom = moment(filterDate).startOf(filterUnit || 'month' );
    const dateTo = moment(filterDate).endOf(filterUnit || 'month' );
    queryString += `&dateRange=${dateFrom},${dateTo}`;
  }
  try {
    response = await getAccountV1Data({
      url: `accountTransactions?${queryString}`,
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}

export async function patchTransactions(row: TransactionProps): Promise<APIResponse<TransactionProps>> {
  let response;
  let errorMessage = '';
  try {
    response = await patchAccountV1Data({
      url: `accountTransactions/${row.id}`,
      data: {
        bookId: row.bookId,
        accountId: row.accountId,
        type: (!row.type && row.baseTransactionType) ? await getTransactionType(row.baseTransactionType.toUpperCase(), row.refund) : row.type,
        transactionDate: row.transactionDate,
        description: row.description,
        debit: row.transactionAmount,
        credit: null,
        referenceCode: row.referenceCode,
        note: row.note,
        status: row.status,
        marked: row.marked,
        tag: row.tag,
        categoryId: row.categoryId,
        transferAccountId: row.transferAccountId,
        linkToTransactionId: row.linkToTransactionId,
      },
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}

export async function postTransactions(row: TransactionProps): Promise<APIResponse<TransactionProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await postAccountV1Data({
      url: 'accountTransactions',
      data: {
        bookId: row.bookId,
        accountId: row.accountId,
        type: (!row.type && row.baseTransactionType) ? await getTransactionType(row.baseTransactionType.toUpperCase(), row.refund) : row.type,
        transactionDate: row.transactionDate,
        description: row.description,
        debit: row.transactionAmount,
        // credit: null,
        referenceCode: row.referenceCode,
        note: row.note,
        status: row.status,
        marked: row.marked,
        tag: row.tag,
        categoryId: row.categoryId,
        transferAccountId: row.transferAccountId,
        linkToTransactionId: row.linkToTransactionId,
      },
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}

export async function deleteTransactions(row: TransactionProps): Promise<APIResponse<TransactionProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await deleteAccountV1Data({
      url: `accountTransactions/${row.id}`,
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}