/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Media from 'react-media';
import {
  getSessionStorageItem,
  mediaQueries,
  useSetState,
} from 'utils';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'elements/Modal';
import Button from 'elements/Button';
import Icon from 'assets/Icon';
import { TextField } from 'elements/Forms';
import { TableOptions, useTable } from 'react-table';
import { ToggleItem } from 'elements/ListItems';
import { getCategories, postCategories, patchCategories, deleteCategories, CategoryProps } from 'services/categoryV1/categories';
import styles from '../CategoryDetail.module.css';
import styles1 from './Categories.module.css';

function Categories() {
  const categorySetId = getSessionStorageItem({ item: 'selectedId' });
  const defaultState = {
    loadingData: true,
    categoryData: [] as CategoryProps[],
    activeRow: {} as CategoryProps,
    modalType: '',
  };
  const [state, setState] = useSetState(defaultState);
  const [modalActive, setModalActive] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);

  const setActiveRow = (valueObj: Partial<CategoryProps>) => {
    setState({ activeRow: { ...state.activeRow, ...valueObj } })
  }

  const toggleModal = (active: boolean) => {
    setModalActive(active);
  };

  const deleteToggleModal = (active: boolean) => {
    setDeleteModalActive(active);
  };

  const addCategory = () => {
    setState({
      activeRow: {},
      modalType: 'Add',
    })
    toggleModal(true);
  };

  const editCategory = (row: CategoryProps) => {
    setState({
      activeRow: row,
      modalType: 'Edit',
    })
    toggleModal(true);
  };

  const deleteCategory = (row: CategoryProps) => {
    setState({
      activeRow: row,
      modalType: 'Delete',
    })
    deleteToggleModal(true);
  };

  const fetchCategories = async () => {
    const tempArray: CategoryProps[] = [];
    if (categorySetId) {
      const { response } = await getCategories({ categorySetId });
      if (response && response.results) {
        response.results.forEach(item => {
          tempArray.push(item);
        });
      }
    }
    setState({
      loadingData: false,
      categoryData: tempArray,
    });
  }

  const handleConfirm = async (modalType: string) => {
    switch (modalType) {    
      case 'Edit':
        return handleEditConfirm();
  
      case 'Add':
        return handleAddConfirm();
  
      case 'Delete':
        return handleDeleteConfirm();
  
      default:
        return state;
    }
  }

  const handleAddConfirm = async () => {
    const { response } = await postCategories(categorySetId, state.activeRow);
    if (response) {
      toggleModal(false);
      fetchCategories();
    }
  }

  const handleEditConfirm = async () => {
    const { response } = await patchCategories(state.activeRow);
    if (response) {
      toggleModal(false);
      fetchCategories();
    }
  }

  const handleDeleteConfirm = async () => {
    const { response } = await deleteCategories(state.activeRow);
    if (!response) {
      deleteToggleModal(false);
      fetchCategories();
    }
  }

  const handleToggle = () => {
    setActiveRow({ status: (state.activeRow.status === 'Y') ? 'N' : 'Y' });
  }

  const Table = ({ columns, data }: TableOptions<CategoryProps>) => {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, rows, prepareRow } = useTable({
      columns,
      data,
    })
  
    // Render the UI for your table
    return (
      <div
        {...getTableProps()}
      >
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <div className={styles1.rowContainer}>
              <div
                className={styles1.rowClass}
                {...row.getRowProps()}
              >
                {row.cells.map(cell => (
                  <div
                    {...cell.getCellProps([
                      {
                        // @ts-ignore
                        className: cell.column.className,
                      },
                    ])}
                    key={cell.column.id}
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    {cell.render('Cell')}
                  </div>
                ))}
              </div>
              <div>
                <Button
                  btnSize="small"
                  btnType="icon"
                  handler={() => editCategory(row.original)}
                >
                  <Icon icon="Edit" fill="white" />
                </Button>
                <Button
                  btnType="icon"
                  btnSize="small"
                  handler={() => deleteCategory(row.original)}
                >
                  <Icon icon="Bin" fill="white" />
                </Button>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'Name',
            accessor: 'name',
            className: '',
          },
          {
            Header: 'Description',
            accessor: 'description',
            className: styles1.describtionCol,
          },
        ],
      },
    ],
    [],
  )

  useEffect(() => {
    setState({ loadingData: true });
    fetchCategories();
  }, [categorySetId]);

  return (
    <div className={styles1.detailContainer}>
      {state.loadingData ? (
        <p>Loading Please wait...</p>
      ) : (
        <>
          <Media query={mediaQueries.md}>
            { matches => matches ? (
              <Link
                to="./"
                className={styles.back}
                style={{ visibility: 'visible' }}
              >
                Back
              </Link>
            ) : (
              <div>&nbsp;</div>
            )}
          </Media>
          <div>
            <div className={styles1.addButton}>
              <div className={styles1.mobileHeading}><h1 className={styles.title}>Category Detail</h1></div>
              <Button
                btnStyle="tertiary"
                btnType="icon"
                elevation="32-8"
                handler={() => addCategory()}
              >
                <Icon icon="Add" fill="white" size={32} />
              </Button>
            </div>
            {(!categorySetId)
              ? <h1 className={styles.title}>Please select category to view the details</h1>
              : ''}
            {(categorySetId && state.categoryData.length === 0)
              ? <h1 className={styles.title}>Please add subcategory</h1>
              : <Table columns={columns} data={state.categoryData} />}
          </div>
          <Modal active={modalActive}>
            <div className={`${styles.user__modal}`}>
              <div className={styles.user__modal__siteForm}>
                <div className={styles.user__modal__siteForm__main}>
                  <ModalHeader heading={(state.modalType === 'Edit') ? 'Edit Sub Category' : 'Add Sub Category'} />
                  <ModalContent>
                    <TextField
                      label="Name"
                      value={state.activeRow.name}
                      handler={e => setActiveRow({ name: e.target.value })}
                    />
                    <TextField
                      label="Description"
                      value={state.activeRow.description}
                      handler={e => setActiveRow({ description: e.target.value })}
                    />
                    <ToggleItem
                      checked={state.activeRow.status === 'Y'}
                      name="status-toggle"
                      handler={() => handleToggle()}
                      label="Status"
                    />                      
                  </ModalContent>
                </div>
              </div>
              <footer className={styles.user__modal__footer}>
                <ModalFooter>
                  <Button
                    btnStyle="secondary"
                    btnType="icon"
                    disabled={!state.activeRow.name}
                    handler={() => handleConfirm(state.modalType)}
                  >
                    Confirm
                  </Button>
                  <Button
                    btnStyle="tertiary"
                    btnType="icon"
                    handler={() => toggleModal(false)}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </footer>
            </div>
          </Modal>
          <Modal active={deleteModalActive}>
            <ModalContent>
              <p>Are you sure you want to delete this?</p>
            </ModalContent>
            <ModalFooter>
              <Button
                btnStyle="tertiary"
                handler={() => deleteToggleModal(false)}
              >
                Cancel
              </Button>
              <Button
                btnStyle="secondary"
                handler={() => handleConfirm(state.modalType)}
              >
                Confirm
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </div>
  )
}

export default Categories
