import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useAuthState } from 'context/AuthContext';
import { getSessionStorageItem } from 'utils';
// Components
import Content from 'pages/CatergoryDetail/CategorySets';
import { CatergoryDetail } from 'pages/CatergoryDetail';
import { Home } from 'pages/Home';
import Join from 'pages/Join';
import CategoryMappings from 'pages/CategoryMappings';
import Transactions from 'pages/Transactions';
import Accounts from 'pages/Accounts';

function Routes() {
  const auth = getSessionStorageItem({ item: 'auth' });
  // const { auth } = useAuthState();
  return (
    auth ?
      ( 
        <Switch>
          <Route
            path="/categories"
            render={props => (
              <CatergoryDetail />
            )}
          />
          <Route exact path="/categorymappings" component={CategoryMappings} />
          <Route exact path="/accounts" component={Accounts} />
          <Route exact path="/transactions" component={Transactions} />
          <Route exact path="/content" component={Content} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/" component={Home} />
          <Route exact path="/join">
            <Redirect to="/home" />
          </Route>
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/content" component={Content} />
          <Route exact path="/join" component={Join} />
          <Route exact path="/" component={Join} />
          <Route exact path="/join">
            <Redirect to="/" />
          </Route>
        </Switch>
      )

  );
}

export default Routes;