import axios from 'axios';
import config from 'config';
import { createApi } from 'utils/api';

const userV1 = axios.create({
  baseURL: config.userV1ApiBaseUrl,
});

const createUserV1Api = createApi(userV1);
export const getUserV1Data = createUserV1Api('get');
export const patchUserV1Data = createUserV1Api('patch');
export const postUserV1Data = createUserV1Api('post');
export const putUserV1Data = createUserV1Api('put');
