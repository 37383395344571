/* eslint-disable react/jsx-key */
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Media from 'react-media';
import {
  getSessionStorageItem,
  mediaQueries,
  useSetState,
} from 'utils';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'elements/Modal';
import Button from 'elements/Button';
import { TextField } from 'elements/Forms';
import { TableOptions, useTable, useExpanded, useGroupBy, useGlobalFilter } from 'react-table';
import { ToggleItem } from 'elements/ListItems';
import { getCategoryMappings, postCategoryMappings, patchCategoryMappings, CategoryMappingProps } from 'services/categoryV1/categoryMappings';
import styles from './CategoryMappings.module.css';

function CategoryMappings() {
  // const cateorySetId = getSessionStorageItem({ item: 'selectedId' });
  const defaultState = {
    loadingData: true,
    categoryMappingData: [] as CategoryMappingProps[],
    activeRow: {} as CategoryMappingProps,
    modalType: '',
  };
  const [state, setState] = useSetState(defaultState);
  const [modalActive, setModalActive] = useState(false);
  const [filterString, setFilterString] = useState('');
  const autoExpended = useRef(true);
  const expandedRow = useRef({});

  const toggleModal = (active: boolean) => {
    setModalActive(active);
  };

  const addCategoryMapping = () => {
    setState({
      activeRow: {},
      modalType: 'Add',
    })
    toggleModal(true);
  };

  const editCategoryMapping = (row: CategoryMappingProps) => {
    setState({
      activeRow: row,
      modalType: 'Edit',
    })
    toggleModal(true);
  };

  const fetchCategoryMappings = async () => {
    const tempArray: CategoryMappingProps[] = [];
    const { response } = await getCategoryMappings();
    if (response && response.results) {
      response.results.forEach(item => {
        tempArray.push(item);
      });
    }
    setState({
      loadingData: false,
      categoryMappingData: tempArray,
    });
  }

  const handleAddConfirm = async () => {
    const { response } = await postCategoryMappings(state.activeRow);
    if (response) {
      toggleModal(false);
      // setState({ submitData: true });
      fetchCategoryMappings();
    }
  }

  const handleEditConfirm = async () => {
    const { response } = await patchCategoryMappings(state.activeRow);
    if (response) {
      toggleModal(false);
      fetchCategoryMappings();
    }
  }

  const setStatus = () => {
    const currStatus = state.activeRow.status === 'Y';
    setState({ 
      activeRow: { ...state.activeRow, 
        status: currStatus ? 'N' : 'Y' }, 
    })
  }

  const Table = ({ columns, data }: TableOptions<CategoryMappingProps>) => {
    // Use the state and functions returned from useTable to build your UI
    const {
      rows,
      isAllRowsExpanded,
      getTableProps,
      prepareRow,
      toggleAllRowsExpanded,
      state: {
        expanded,
      },
    } = useTable({
      columns,
      data,
      initialState: {
        globalFilter: filterString,
        groupBy: ['categorySetName'],
        expanded: expandedRow.current,
      },
      autoResetExpanded: false,
      autoResetGroupBy: false,
    },
    useGlobalFilter,
    useGroupBy,
    useExpanded,
    );

    expandedRow.current = expanded;
    
    if (rows.length > 0 && Object.keys(expandedRow.current).length === 0 && !isAllRowsExpanded && autoExpended.current) {
      toggleAllRowsExpanded(true);
    }
  
    // Render the UI for your table
    return (
      <div
        {...getTableProps()}
      >
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <>
              <div
                className={styles.rowClass}
                {...row.getRowProps()}
              >
                {row.cells.map(cell => (
                  <div
                    {...cell.getCellProps()}
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    {cell.render('Cell')}
                  </div>
                ))}
              </div>
              <Button
                handler={() => editCategoryMapping(row.original)}
              >Edit
              </Button>
            </>
          )
        })}
      </div>
    )
  }

  const columns = React.useMemo(() => [{
    Header: 'None',
    columns: [
      {
        Header: 'Seq No',
        accessor: 'sequenceNumber',
      },
      {
        Header: 'Type',
        accessor: 'mappingType',
      },
      {
        Header: 'Account',
        accessor: 'accountNickName',
      },
      {
        Header: 'Keyword',
        accessor: 'keyword',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Category Set',
        accessor: 'categorySetName',
      },
      {
        Header: 'Category Name',
        accessor: 'categoryName',
      },
      {
        Header: 'Transfer Account',
        accessor: 'TransferAccountNickName',
      },
    ],
  }], []);

  useEffect(() => {
    setState({ loadingData: true });
    fetchCategoryMappings();
  }, []);

  return (
    <div>
      {state.loadingData ? (
        <p>Loading Please wait...</p>
      ) : (
        <>
          <Media query={mediaQueries.md}>
            { matches => matches ? (
              <Link
                to="./"
                className={styles.back}
                style={{ visibility: 'visible' }}
              >
                Back
              </Link>
            ) : (
              <div>&nbsp;</div>
            )}
          </Media>
          <Button btnStyle="tertiary" handler={() => addCategoryMapping()}>
            Add CategoryMapping
          </Button>
          {(state.categoryMappingData.length === 0)
            ? <h1 className={styles.title}>Please add category mapping</h1>
            : <Table columns={columns} data={state.categoryMappingData} />}
          <Modal active={modalActive}>
            <div className={`${styles.user__modal}`}>
              <div className={styles.user__modal__siteForm}>
                <div className={styles.user__modal__siteForm__main}>
                  <ModalHeader heading={(state.modalType === 'Edit') ? 'Edit Category Mapping' : 'Add Category Mapping'} />
                  <ModalContent>
                    <TextField
                      label="Seq No"
                      value={state.activeRow.sequenceNumber}
                      handler={e => setState({ activeRow: { ...state.activeRow, sequenceNumber: e.target.value } })}
                    />
                    <TextField
                      label="Direction"
                      value={state.activeRow.mappingType}
                      handler={e => setState({ activeRow: { ...state.activeRow, mappingType: e.target.value } })}
                    />
                    <TextField
                      label="Account"
                      value={state.activeRow.accountId}
                      handler={e => setState({ activeRow: { ...state.activeRow, accountId: Number(e.target.value) } })}
                    />
                    <TextField
                      label="Keyword"
                      value={state.activeRow.keyword}
                      handler={e => setState({ activeRow: { ...state.activeRow, keyword: e.target.value } })}
                    />
                    <TextField
                      label="Keyword 2"
                      value={state.activeRow.keyword2}
                      handler={e => setState({ activeRow: { ...state.activeRow, keyword2: e.target.value } })}
                    />
                    <TextField
                      label="Description"
                      value={state.activeRow.description}
                      handler={e => setState({ activeRow: { ...state.activeRow, description: e.target.value } })}
                    />
                    <TextField
                      label="Category"
                      value={state.activeRow.categoryName}
                      handler={e => setState({ activeRow: { ...state.activeRow, categoryName: e.target.value } })}
                    />
                    <TextField
                      label="Transfer Account"
                      value={state.activeRow.transferAccountId}
                      handler={e => setState({ activeRow: { ...state.activeRow, transferAccountId: Number(e.target.value) } })}
                    />
                    <ToggleItem
                      checked={state.activeRow.status === 'Y'}
                      name="status-toggle"
                      handler={() => setStatus()}
                      label="Status"
                    />                      
                  </ModalContent>
                </div>
              </div>
              <footer className={styles.user__modal__footer}>
                <ModalFooter>
                  <Button
                    btnStyle="secondary"
                    btnType="icon"
                    disabled={!state.activeRow.keyword}
                    handler={(state.modalType === 'Edit') ? handleEditConfirm : handleAddConfirm}
                  >
                    Confirm
                  </Button>
                  <Button btnType="icon" btnStyle="tertiary" handler={() => toggleModal(false)}>
                    Cancel
                  </Button>
                </ModalFooter>
              </footer>
            </div>
          </Modal>
        </>
      )}
    </div>
  )
}

export default CategoryMappings
