import React from 'react';
import styles from './style/modal.module.css';

interface ModalProps {
  active?: boolean,
  children?: React.ReactNode,
}

const Modal = ({ active, children }: ModalProps) => (
  <div className={`${styles.modal} ${active ? styles.modal_active : ''}`}>
    <div className={styles.modal__content}>
      { children }
    </div>
  </div>
);

Modal.defaultProps = {
  active: false,
  children: null,
};

export default Modal;
