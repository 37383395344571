/* import React, { createContext, useContext, useReducer } from 'react';
import { AppContextProps, AppDispatchProps } from 'types/global';
import reducer from './reducer';

/* export const initialState: AppContextProps = {
  auth: false,
  authState: '',
  user: {},
  v2tkn: {},
} */

/* export const AuthStateContext = createContext<AppContextProps | {}>({}) as React.Context<AppContextProps>;

export const AuthDispatchContext = createContext<React.Dispatch<AppDispatchProps> | null>(null) as React.Context<React.Dispatch<AppDispatchProps>>;

function AuthContext({ children }: { children: React.ReactNode }) {

  const initialState: AppContextProps = {
    auth: false,
    authState: '',
    user: {},
    v2tkn: {},
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

function useAuthDispatch() {
  const context = useContext(AuthDispatchContext)
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthState Provider')
  }
  return context
}

function useAuthState() {
  const context = useContext(AuthStateContext)
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthSate Provider')
  }
  return context
}

export { AuthContext, useAuthDispatch, useAuthState }; */


import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import reducer from './reducer';

export const AuthStateContext = React.createContext();
export const AuthDispatchContext = React.createContext();

export const initialState = {
  auth: false,
  authState: '',
  user: {},
  v2tkn: {},
};

function AuthContext({ children }) {
  // State

  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <Router>
      <AuthStateContext.Provider value={state}>
        <AuthDispatchContext.Provider value={dispatch}>
          {children}
        </AuthDispatchContext.Provider>
      </AuthStateContext.Provider>
    </Router>
  );
}

AuthContext.propTypes = {
  children: PropTypes.node.isRequired,
};

function useAuthState() {
  const context = React.useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error('useAppState must be used within a AppContext');
  }
  return context;
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppContext');
  }
  return context;
}

export { AuthContext, useAuthState, useAuthDispatch };
