import React from 'react';
// Assets
import { LogoMin } from '../../assets/Logos';
// Components
import NavBar from '../NavBar';
// Style
import styles from './header.module.css';

function Header() {
  return (
    <header className={`${styles.container} wrapper`}>
      <div className={`${styles.content} wrapper__content`}>
        <a href="/" className={styles.brandlLogo}>
          <LogoMin />
          <div className={styles.brandlLogoName}>
            PennyVue
          </div>
        </a>
        <NavBar />
      </div>
    </header>
  );
}

export default Header;
