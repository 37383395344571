import { AppContextProps, AppDispatchProps } from 'types/global';
import * as actionTypes from './actionTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function reducer(state:{}, action:{ payload: any, type: string }) {
  switch (action.type) {
    case actionTypes.SET_AUTH_STATE:
      return {
        ...state,
        authState: action.payload,
      };

    case actionTypes.AUTHORISE_USER:
      return {
        ...state,
        auth: true,
      };
    
    case actionTypes.AUTHORIZE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        auth: true,
      };

    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
  
    case actionTypes.SET_TOKEN:
      return {
        ...state,
        v2tkn: action.payload,
      };

    case actionTypes.UNAUTHORISE_USER:
      return {
        ...state,
        auth: false,
        v2tkn: {},
      };

    default:
      return state;
  }
}

export default reducer;
