import React, { useState } from 'react';
// Context
import { useAuthDispatch, useAuthState } from 'context/AuthContext';
import { initialiseLogout } from 'context/AuthContext/middleware';
// Elements
import Button from 'elements/Button';
// Style
import styles from './navbar.module.css';

function NavBar() {
  const [loading, setLoading] = useState(false);
  const { auth, user : { firstName } } = useAuthState();
  const authDispatch = useAuthDispatch();
  async function handleLogout() {
    setLoading(true);
    initialiseLogout(authDispatch);
  }
  return (
    <nav className={styles.container}>
      <ul>
        <li><Button btnType="icon" btnStyle={styles.link} href="discover">Discover</Button></li>
        {auth ?
          ( 
            <>
              <li><Button btnType="icon">Welcome {firstName}</Button></li>
              <li><Button btnType="icon" btnStyle={styles.link} href="categories">categories</Button></li>
              <li>
                <Button btnType="icon" btnStyle={styles.link} handler={handleLogout}>Logout</Button>
              </li>
            </>
          ) : (<li><Button btnType="icon" btnStyle={styles.link} href="join">Join</Button></li>)}
      </ul>
    </nav>
  );
}

export default NavBar;
