/* eslint-disable max-len */
import React from 'react';

function LogoMin({ height }: { height?: number }) {
  return (
    <svg width="30" height="50" viewBox="0 0 37 94" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line y1="-1.5" x2="34.4014" y2="-1.5" transform="matrix(0.252392 0.967625 -0.797198 0.603718 20 50)" stroke="#C7BC59" strokeWidth="3" />
      <line y1="-1" x2="26.2518" y2="-1" transform="matrix(-0.165371 0.986231 -0.899112 -0.43772 33.3413 54)" stroke="#C7BC59" strokeWidth="2" />
      <g filter="url(#filter0_d)">
        <mask id="path-3-inside-1" fill="white">
          <path d="M30.3466 34.6856C31.7317 33.655 32.4953 31.9815 32.3126 30.2647C31.5834 23.4156 30.2978 17.2001 28.5655 12.1884C26.6029 6.51028 24.1465 2.6073 21.478 0.927343C18.8096 -0.752609 16.0374 -0.141453 13.4795 2.69067C10.9216 5.52279 8.68172 10.4611 7.01689 16.9389C5.35206 23.4166 4.32976 31.1713 4.06733 39.313C3.80489 47.4546 4.31294 55.6533 5.53318 62.968C6.75342 70.2828 8.63639 76.4172 10.966 80.6672C12.0357 82.6186 13.1796 84.1365 14.368 85.1964C16.3744 86.9859 18.6937 84.8681 18.6812 82.1796L18.5118 46.0266C18.5044 44.4379 19.2524 42.9401 20.5271 41.9918L30.3466 34.6856Z" />
        </mask>
        <path d="M30.3466 34.6856C31.7317 33.655 32.4953 31.9815 32.3126 30.2647C31.5834 23.4156 30.2978 17.2001 28.5655 12.1884C26.6029 6.51028 24.1465 2.6073 21.478 0.927343C18.8096 -0.752609 16.0374 -0.141453 13.4795 2.69067C10.9216 5.52279 8.68172 10.4611 7.01689 16.9389C5.35206 23.4166 4.32976 31.1713 4.06733 39.313C3.80489 47.4546 4.31294 55.6533 5.53318 62.968C6.75342 70.2828 8.63639 76.4172 10.966 80.6672C12.0357 82.6186 13.1796 84.1365 14.368 85.1964C16.3744 86.9859 18.6937 84.8681 18.6812 82.1796L18.5118 46.0266C18.5044 44.4379 19.2524 42.9401 20.5271 41.9918L30.3466 34.6856Z" fill="#306595" />
        <path d="M30.3466 34.6856C31.7317 33.655 32.4953 31.9815 32.3126 30.2647C31.5834 23.4156 30.2978 17.2001 28.5655 12.1884C26.6029 6.51028 24.1465 2.6073 21.478 0.927343C18.8096 -0.752609 16.0374 -0.141453 13.4795 2.69067C10.9216 5.52279 8.68172 10.4611 7.01689 16.9389C5.35206 23.4166 4.32976 31.1713 4.06733 39.313C3.80489 47.4546 4.31294 55.6533 5.53318 62.968C6.75342 70.2828 8.63639 76.4172 10.966 80.6672C12.0357 82.6186 13.1796 84.1365 14.368 85.1964C16.3744 86.9859 18.6937 84.8681 18.6812 82.1796L18.5118 46.0266C18.5044 44.4379 19.2524 42.9401 20.5271 41.9918L30.3466 34.6856Z" stroke="black" strokeOpacity="0.01" strokeWidth="2" mask="url(#path-3-inside-1)" />
      </g>
      <ellipse cx="19" cy="22.5" rx="8" ry="9.5" fill="#C7BC59" />
      <defs>
        <filter id="filter0_d" x="0" y="0" width="36.3393" height="93.8585" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg> 
  );
}

LogoMin.defaultProps = {
  height: 40,
};

export default LogoMin;
