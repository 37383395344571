export default {
  mock: {
    userV1AuthApiBaseUrl: 'https://dev.api.pennyvue.com/v1/auth/',
    apiKey: 'CqSSnGFWWGeEXhOJZYhgBFMgcTQBkIpB',
    uiDelay: 0,
    logoutTimer: 1800000,
    eventLogRefreshRate: 60000,
    appStatusInterval: 5000,
  },
  local: {
    authV1ApiBaseUrl: 'https://dev.api.pennyvue.com/v1/auth/',
    userV1ApiBaseUrl: 'https://dev.api.pennyvue.com/v1/users/',
    categoryV1ApiBaseUrl: 'https://dev.api.pennyvue.com/v1/',
    accountV1ApiBaseUrl: 'https://dev.api.pennyvue.com/v1/',
    apiKey: 'CqSSnGFWWGeEXhOJZYhgBFMgcTQBkIpB',
    uiDelay: 0,
    logoutTimer: 1800000,
    eventLogRefreshRate: 60000,
    appStatusInterval: 5000,
  },
  dev: {
    authV1ApiBaseUrl: 'https://dev.api.pennyvue.com/v1/auth/',
    userV1ApiBaseUrl: 'https://dev.api.pennyvue.com/v1/users/',
    categoryV1ApiBaseUrl: 'https://dev.api.pennyvue.com/v1/',
    accountV1ApiBaseUrl: 'https://dev.api.pennyvue.com/v1/',
    apiKey: 'CqSSnGFWWGeEXhOJZYhgBFMgcTQBkIpB',
    uiDelay: 500,
    logoutTimer: 1800000,
    eventLogRefreshRate: 60000,
    appStatusInterval: 60000,
  },
  sit: {
    authV1ApiBaseUrl: 'https://sit.api.pennyvue.com/v1/auth/',
    userV1ApiBaseUrl: 'https://sit.api.pennyvue.com/v1/users/',
    categoryV1ApiBaseUrl: 'https://sit.api.pennyvue.com/v1/',
    accountV1ApiBaseUrl: 'https://sit.api.pennyvue.com/v1/',
    apiKey: 'CqSSnGFWWGeEXhOJZYhgBFMgcTQBkIpB',
    uiDelay: 0,
    logoutTimer: 1800000,
    eventLogRefreshRate: 60000,
    appStatusInterval: 5000,
  },
  prod: {
    authV1ApiBaseUrl: 'https://api.pennyvue.com/v1/auth/',
    userV1ApiBaseUrl: 'https://api.pennyvue.com/v1/users/',
    categoryV1ApiBaseUrl: 'https://api.pennyvue.com/v1/',
    accountV1ApiBaseUrl: 'https://api.pennyvue.com/v1/',
    apiKey: 'Zc8zWAQCDkTuMCZA8j3VO7sAxhe3Z8hl',
    uiDelay: 0,
    logoutTimer: 1800000,
    eventLogRefreshRate: 60000,
    appStatusInterval: 60000,
  },
}[process.env.REACT_APP_ENV || 'local']!;
