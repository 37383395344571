import { getAccountV1Data, patchAccountV1Data, postAccountV1Data, deleteAccountV1Data } from 'services/accountV1';
import { APIBaseProps, APIResponse } from 'types/global';

export interface AccountProps extends APIBaseProps<AccountProps> {
  id?: number,
  accountNumber?: string,
  iban?: string,
  nickName?: string,
  description?: string,
  accountType?: string,
  currency?: string,
  bankCode?: string,
  branchCode?: string,
  branchAddress?: string,
  ifscCode?: string,
  micrCode?: string,
  accountUserCode?: string,
  startDate?: Date,
  withdrawalLimit?: number,
  remainingAmount?: number,
  interestRate?: number,
  depositAmount?: number,
  maturityDate?: Date,
  maturityAmount?: number,
  status?: string,
  closeDate?: Date,
  linkToAccountId?: number,
  autoTransaction?: string,
}

export async function getAccounts(): Promise<APIResponse<AccountProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await getAccountV1Data({
      url: 'accounts',
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}

export async function patchAccounts(row: AccountProps): Promise<APIResponse<AccountProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await patchAccountV1Data({
      url: `accounts/${row.id}`,
      data: {
        accountNumber: row.accountNumber,
        iban: row.iban,
        nickName: row.nickName,
        description: row.description,
        currency: row.currency,
        bankCode: row.bankCode,
        branchCode: row.branchCode,
        branchAddress: row.branchAddress,
        ifscCode: row.ifscCode,
        micrCode: row.micrCode,
        accountUserCode: row.accountUserCode,
        startDate: row.startDate,
        withdrawalLimit: row.withdrawalLimit,
        remainingAmount: row.remainingAmount,
        interestRate: row.interestRate,
        depositAmount: row.depositAmount,
        maturityDate: row.maturityDate,
        maturityAmount: row.maturityAmount,
        status: row.status,
        closeDate: row.closeDate,
        linkToAccountId: row.linkToAccountId,
        autoTransaction: row.autoTransaction,
      },
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}

export async function postAccounts(row: AccountProps): Promise<APIResponse<AccountProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await postAccountV1Data({
      url: 'accounts',
      data: {
        accountNumber: row.accountNumber,
        iban: row.iban,
        nickName: row.nickName,
        description: row.description,
        accountType: row.accountType,
        currency: row.currency,
        bankCode: row.bankCode,
        branchCode: row.branchCode,
        branchAddress: row.branchAddress,
        ifscCode: row.ifscCode,
        micrCode: row.micrCode,
        accountUserCode: row.accountUserCode,
        startDate: row.startDate,
        withdrawalLimit: row.withdrawalLimit,
        remainingAmount: row.remainingAmount,
        interestRate: row.interestRate,
        depositAmount: row.depositAmount,
        maturityDate: row.maturityDate,
        maturityAmount: row.maturityAmount,
        status: row.status,
        closeDate: row.closeDate,
        linkToAccountId: row.linkToAccountId,
        autoTransaction: row.autoTransaction,
      },
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}

export async function deleteAccounts(row: AccountProps): Promise<APIResponse<AccountProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await deleteAccountV1Data({
      url: `accounts/${row.id}`,
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}