import React, { useEffect, useRef, useState } from 'react';
import { validArray } from 'utils';
import { CheckItem } from 'elements/ListItems';
import { ListProps } from 'types/global';
import styles from './style/select.module.css';
import SelectToggle from './SelectToggle';

interface SelectProps {
  name: string,
  label: string,
  items: ListProps[] | boolean,
  value?: string | boolean,
  valueId?: string | boolean,
  disabled?: boolean,
  handleOnChange: (value: string, id: string) => void,
}

const SelectWithId = ({
  disabled,
  handleOnChange,
  items,
  label,
  name,
  value,
  valueId,
}: SelectProps) => {
  const [open, setOpen] = useState(false);
  const select = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (select.current && select.current.contains(e.target as Node)) {
      return;
    }
    setOpen(false);
  };

  const toggleOpen = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <div
      className={`
        ${styles.select}
        ${open ? styles.select_open : ''}
        ${value ? styles.select_hasValue : ''}
        ${disabled ? styles.select_disabled : ''}
      `}
      onClick={toggleOpen}
      onKeyDown={toggleOpen}
      ref={select}
      role="button"
      tabIndex={0}
    >
      <SelectToggle
        active={open}
        disabled={disabled}
        label={label}
        value={value}
      />
      <div className={styles.select__options}>
        {validArray(items) && (items as ListProps[]).map(item => (
          <CheckItem
            borderInset={1}
            checked={`${value}:${valueId}` === `${item.value}:${item.id}`}
            handler={e => {
              const [val, id] = (e.target as HTMLButtonElement).value.split(':');
              handleOnChange(val, id);
            }}
            key={item.id}
            label={item.value && item.value.replace(/_|-/g, ' ')}
            name={name}
            value={`${item.value}:${item.id}`}
          />
        ))}
      </div>
    </div>
  );
};

SelectWithId.defaultProps = {
  disabled: false,
  value: false,
  valueId: false,
};

export default SelectWithId;
