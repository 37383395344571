import React, { useEffect, useRef, useState } from 'react';
import { validArray } from 'utils';
import { ListItem } from 'elements/ListItems';
import Icon from 'assets/Icon';
// import styles from 'pages/CatergoryDetail/CategoryDetail.module.css';
import styles from './style/actionMenu.module.css';

interface ActionMenuProps {
  id?: string,
  items: string[],
  handler: (act: string) => void,
  disabled?: boolean,
  size?: string,
}

const ActionMenu = ({
  id, items, handler, disabled, size,
}: ActionMenuProps) => {
  const [open, setOpen] = useState(false);
  const morePopOver = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (morePopOver.current && morePopOver.current.contains(e.target as Node)) {
      return;
    }
    setOpen(false);
  };

  const toggleOpen = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <div
      className={`
        ${styles.exception__actions__popOver} ${open && styles.exception__actions__popOver_active}`}
      ref={morePopOver}
    >
      <button
        id={id}
        className={`${styles.actionButton} ${size === 'sm' ? styles.actionButton_sm : ''}`}
        type="button"
        onClick={() => toggleOpen()}
      >
        <Icon icon="MoreAlt" fill="white" />
      </button>
      { open && (
        <div className={styles.exception__actions__popOver__info}>
          {validArray(items) && (items as string[]).map(item => (
            <ListItem
              key={item}
              handler={e => handler((e.target as HTMLButtonElement).value)}
              size={size}
              value={item}
            >
              <span className={styles.actionButtonList}>{item}</span>
            </ListItem>
          ))}
        </div>
      )}
    </div>
  );
};

ActionMenu.defaultProps = {
  id: '',
  disabled: false,
  size: '',
};

export default ActionMenu;
