/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Media from 'react-media';
import { mediaQueries } from 'utils/media-queries.model';
import CategorySet from 'pages/CatergoryDetail/CategorySets';
import Categories from 'pages/CatergoryDetail/Categories';
import styles from './CategoryDetail.module.scss';

export interface CatergoryDetailProps {
  MasterType?: any,
  masterProps?: any,
  DetailType?:  any,
  detailProps?: any
}

export const CatergoryDetail: React.FC<CatergoryDetailProps> = () => {
  const { path } = useRouteMatch();

  return ( 
    <Media query={mediaQueries.md}>
      {matches =>
        matches ? (
          <Switch>
            <Route exact path={`${path}`}>              
              <CategorySet />
            </Route>
            <Route path={`${path}/detail`}>
              <Categories />
            </Route>
          </Switch>
        ) : (
          <section className={styles.component}>
            <section className={styles.master}>
              <Route path={`${path}`}>
                <CategorySet />
              </Route>
            </section>
            <section className={styles.detail}>
              <Switch>
                <Route exact path={`${path}`}>
                  <Categories />
                </Route>
                <Route path={`${path}/detail`}>
                  <Categories />
                </Route>
              </Switch>
            </section>
          </section>
        )}
    </Media>
  );
};