// import { postAuthV1Data } from 'services/authV1';
import { APIResponse } from 'types/global';

export interface AuthProps {
  email?: string,
  password?: string,
  access?: { token: string, expires: Date },
  refresh?: { token: string, expires: Date },
}

export async function refresh(refreshToken: string): Promise<APIResponse<AuthProps>> {
  const response = {};
  const errorMessage = '';  
  /* try {
    response = await postAuthV1Data({
      data: { refreshToken },
      url: 'refresh-tokens',
      withAuth: false,
    });
  } catch (error) {
    errorMessage = error.message;
  } */
  return { response, errorMessage };
}
