import React from 'react';
import Icon from 'assets/Icon';
import styles from './style/listItem.module.css';

interface CheckItemProps {
  borderInset?: number,
  checked?: boolean,
  disabled?: boolean,
  handler?: React.MouseEventHandler<HTMLButtonElement>,
  label?: string,
  name?: string,
  value?: string,
}

const CheckItem = ({
  borderInset,
  checked,
  disabled,
  handler,
  label,
  name,
  value,
}: CheckItemProps) => (
  <button
    className={styles.listItem}
    data-border-inset={borderInset}
    disabled={disabled}
    name={name}
    onClick={handler ? (e) => handler(e) : () => null}
    type="button"
    value={value}
  >
    { label }
    { checked ? (
      <Icon icon="CheckboxActive" fill="blueberry" />
    ) : (
      <Icon icon="Checkbox" fill="warm-grey-100" />
    )}
  </button>
);

CheckItem.defaultProps = {
  borderInset: 0,
  checked: false,
  disabled: false,
  handler: null,
  label: 'Check Item',
  name: '',
  value: '',
};

export default CheckItem;
