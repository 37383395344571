export const AUTHORISE_USER = 'AUTHORISE_USER';
export const AUTHORISE_USER_ERROR = 'AUTHORISE_USER_ERROR';
export const AUTHORISE_USER_INACTIVE = 'AUTHORISE_USER_INACTIVE';
export const AUTHORISE_USER_SUCCESS = 'AUTHORISE_USER_SUCCESS';
export const AUTHORISE_USER_RETURN = 'AUTHORISE_USER_RETURN';
export const AUTHORISE_USER_RETURN_ERROR = 'AUTHORISE_USER_RETURN_ERROR';
export const AUTHORISE_USER_RETURN_SUCCESS = 'AUTHORISE_USER_RETURN_SUCCESS';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const PATCH_LAST_LOGIN = 'PATCH_LAST_LOGIN';
export const PATCH_LAST_LOGIN_ERROR = 'PATCH_LAST_LOGIN_ERROR';
export const PATCH_LAST_LOGIN_SUCCESS = 'PATCH_LAST_LOGIN_SUCCESS';
export const POST_USER_TOKEN = 'SET_USER_TOKEN';
export const POST_USER_TOKEN_ERROR = 'SET_USER_TOKEN_ERROR';
export const POST_USER_TOKEN_SUCCESS = 'SET_USER_TOKEN_SUCCESS';
export const UNAUTHORISE_USER = 'UNAUTHORISE_USER';
export const UNAUTHORISE_USER_ERROR = 'UNAUTHORISE_USER_ERROR';
