import React from 'react';
import Toggle from 'elements/Forms/components/Toggle';
import styles from './style/listItem.module.css';

interface ToggleItemProps {
  borderInset?: number,
  checked?: boolean,
  handler?: React.MouseEventHandler<HTMLButtonElement>,
  label?: string,
  name?: string,
  value?: string,
}

const ToggleItem = ({
  borderInset,
  checked,
  handler,
  label,
  name,
  value,
}: ToggleItemProps) => (
  <button
    className={styles.listItem}
    data-border-inset={borderInset}
    name={name}
    onClick={handler}
    type="button"
    value={value}
  >
    { label }
    <Toggle active={checked} />
  </button>
);

ToggleItem.defaultProps = {
  borderInset: 0,
  checked: false,
  handler: null,
  label: 'Toggle Item',
  name: '',
  value: '',
};

export default ToggleItem;
