/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useRef, useEffect, useState, useCallback } from 'react';

import { login } from 'services/authV1/login';
import { useHistory } from 'react-router-dom';
import { useAuthDispatch, useAuthState } from 'context/AuthContext';
import {
  useSetState,
  setSessionStorageItem,
  getSessionStorageItem,
} from 'utils';
// Types
import { APIResponse } from 'types/global';
// eslint-disable-next-line import/named
import { authoriseUserReturn, initialiseLogin } from 'context/AuthContext/middleware';
import Button from 'elements/Button';
import { TextField } from 'elements/Forms';
import styles from './join.module.css';

const Join = () => {
  const form = useRef();
  const dispatch = useAuthDispatch();
  // const checkBtn = useRef();
  const defaultState = {
    username: '',
    password:'a1234567890',
    email:'n3c.ravish@gmail.com',
    message: '',
    loading: false,
  };
  const [state, setState] = useSetState(defaultState);
  // const { errorMsg, appState, appStateMsg } = useAuthState();
  const [showLogin, setShowLogin] = useState(false);
  const [logoutLoader, setLogoutLoader] = useState(false);
  const [logout, setLogout] = useState(false);
  const history = useHistory();

  const handleLogin = useCallback(async () => {
    await initialiseLogin(state.email, state.password, dispatch);
    window.location.reload();
  }, [dispatch, state.email, state.password]);

  const handleLoginSession = async () => {
    const authSession = getSessionStorageItem({ item: 'auth' });
    if (authSession) {
      authoriseUserReturn(dispatch);
    } else {
      setShowLogin(true);
      // await initialiseLogin(state.email, state.password, dispatch);
      setLogoutLoader(true);
      setTimeout(() => {
        setTimeout(() => {
          setLogoutLoader(false);
        }, 400);
      }, 1500);
    }
  };

  useEffect(() => {
    handleLoginSession();
  }, []);

  return showLogin ?
    (
      <>
        <section className={styles.joinMainSection}>
          <div className={styles.callToAction}>
            <h1 className={styles.title}>Know what you own, and know why you own it.</h1>
            <div className={styles.displayrow}><h1 className={styles.title}>Embrace the &nbsp;</h1><h1 className={styles.colortitle}>Power.</h1></div>
          </div>
        </section>
        <section className="wrapper">
          <div className="wrapper__content">
            <div className={styles.container}>
              <div className={styles.grid}>
                <TextField
                  label="Email:"
                  value={state.email}
                  handler={e => setState({ email: e.target.value })}
                />
                <TextField
                  label="Password:"
                  value={state.password}
                  handler={e => setState({ password: e.target.value })}
                />
                <Button btnType="icon" btnStyle="tertiary" handler={handleLogin}>Join Now</Button>
              </div>
            </div>
          </div>
        </section>
      </>
    ) : null;
};

export default Join;
