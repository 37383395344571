import { getSessionStorageItem, setSessionStorageItem } from 'utils';
import { refresh } from 'services/authV1/login/refresh';
import { AxiosInstance, AxiosRequestConfig } from 'axios';

interface ApiParam<D> {
  url: string,
  apiConfig?: AxiosRequestConfig,
  data?: D,
  withAuth?: boolean,
}

type Method =
  | 'get'
  | 'delete'
  | 'head'
  | 'options'
  | 'post'
  | 'put'
  | 'patch'

export const NETWORK_FAIL_MSG = 'There is a network error: Please contact a Supervisor before continuing';

export const isExpired = () => {
  const expiry = getSessionStorageItem({ item:'v2tkn' }).tokenExpiry;
  if (!expiry) {
    return false;
  }

  return Date.now() > new Date(expiry).getTime();
}

export async function getToken() {
  if (isExpired()) {
    const { response } = await refresh(getSessionStorageItem({ item:'v2tkn' }).refreshToken);
    if (response && response.access && response.refresh) {
      const v2tkn = {
        v2tkn: response.access.token,
        tokenExpiry: response.access.expires,
        refreshToken: response.refresh.token,
      };
      setSessionStorageItem({ item: 'v2tkn', value: v2tkn });
    }
  }
  const token = getSessionStorageItem({ item: 'v2tkn' });
  return token.v2tkn;
}

async function addBearerToken(apiConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  const headers = apiConfig.headers || {};
  headers.Authorization = `Bearer ${await getToken()}`;
  return {
    ...apiConfig,
    headers,
  }
}

export class ApiError extends Error {
  apiStatusCode: string;

  apiStatusText: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiResponseData: any;
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(statusCode: string, statusText: string, responseData: any, ...params: any[]) {
    super(...params);
    this.apiStatusCode = `${statusCode}`;
    this.apiStatusText = statusText;
    this.apiResponseData = responseData;
  }
}

/* istanbul ignore next */ 
export const createApi = (api: AxiosInstance) => (method: Method) => async <D>({
  url,
  apiConfig = {},
  data,
  withAuth,
}: ApiParam<D>) => {
  try {
    // const auth = sessionStorage.getItem('auth');
    const config = withAuth ? await addBearerToken(apiConfig) : { ...apiConfig };
    /* const response = await api[method](
      url,
      data || config,
      data ? config : undefined,
    ); */
    const response = data ? await api[method](url, data, config) : await api[method](url, config)
    return response.data;
  } catch (error) {
    const { response } = error;
    if (response) {
      throw new ApiError(
        response.status,
        response.statusText,
        response.data,
        error,
      );
    } else {
      throw new Error(NETWORK_FAIL_MSG);
    }
  }
};
