import { getCategoryV1Data, patchCategoryV1Data, postCategoryV1Data, deleteCategoryV1Data } from 'services/categoryV1';
import { APIBaseProps, APIResponse } from 'types/global';

export interface CategorySetProps extends APIBaseProps<CategorySetProps> {
  id?: number,
  name?: string,
  description?: string,
  type?: string,
  startDate?: Date,
  endDate?: Date,
  status?: string,
}

export async function getCategory(): Promise<APIResponse<CategorySetProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await getCategoryV1Data({
      url: 'categorySets',
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}

export async function patchCategory(row: CategorySetProps): Promise<APIResponse<CategorySetProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await patchCategoryV1Data({
      url: `categorysets/${row.id}`,
      data: { name: row.name, description: row.description, status: row.status },
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}

export async function deleteCategory(row: CategorySetProps): Promise<APIResponse<CategorySetProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await deleteCategoryV1Data({
      url: `categorysets/${row.id}`,
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}

export async function postCategory(row: CategorySetProps): Promise<APIResponse<CategorySetProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await postCategoryV1Data({
      url: 'categorysets',
      data: { name: row.name, description: row.description, type: (row.type || '').toUpperCase(), status: row.status },
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}
