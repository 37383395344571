import React from 'react';
import styles from './style/tab.module.css';

interface TabProps {
  value: string,
  label?: string,
  active?: boolean,
  disabled?: boolean,
  handler: (tab: string) => void,
}

const Tab = ({
  value,
  label,
  active,
  disabled,
  handler,
}: TabProps) => {
  const handleClick = () => {
  //  handler(label);
  };

  return (
    <button
      className={`${styles.tab} ${active ? styles.tab_active : ''}`}
      onClick={e => handler((e.target as HTMLButtonElement).value)}
      type="button"
      disabled={disabled}
      value={value}
    >
      { label || value }
    </button>
  );
};

Tab.defaultProps = {
  label: '',
  active: false,
  disabled: false,
};

export default Tab;
