import React from 'react';
// Elements
import Button from 'elements/Button';
// Style
import styles from './footer.module.css';

function Footer() {
  return (
    <footer className={styles.container}>
      <ul>
        <li>
          <Button btnType="icon" btnSize="small" btnStyle={styles.link}>Contact</Button>
        </li>
        <li>
          <Button btnType="icon" btnSize="small" btnStyle={styles.link}>About Us</Button>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
