import React from 'react';
import icons from './icons';

export interface IconProps {
  icon: keyof typeof icons,
  fill?: string,
  size?: string | number,
}

function Icon({ icon, fill, size }: IconProps) {
  return (
    <svg height={size} width={size} viewBox="0 0 24 24">
      <path
        fill={`var(--${fill})`}
        d={icons[icon]}
      />
    </svg>
  );
}

Icon.defaultProps = {
  fill: 'black',
  icon: '',
  size: 24,
};

export default Icon;
