import React from 'react';
import { Link } from 'react-router-dom';
import styles from './style/listItem.module.css';

interface ListItemProps {
  children: React.ReactNode,
  borderInset?: number,
  handler?: React.MouseEventHandler<HTMLButtonElement>,
  href?: string,
  size?: string,
  value?: string,
}

const ListItem = ({
  borderInset,
  children,
  handler,
  href,
  size,
  value,
}: ListItemProps) => {
  if (handler) {
    return (
      <button
        className={`${styles.listItem} ${size === 'sm' ? styles.listItem_sm : ''}`}
        data-border-inset={borderInset}
        onClick={handler}
        type="button"
        value={value}
      >
        { children }
      </button>
    );
  }
  if (href) {
    return (
      <Link
        className={`${styles.listItem} ${size === 'sm' ? styles.listItem_sm : ''}`}
        data-border-inset={borderInset}
        to={href}
      >
        { children }
      </Link>
    );
  }
  return (
    <div
      className={`${styles.listItem} ${size === 'sm' ? styles.listItem_sm : ''}`}
      data-border-inset={borderInset}
    >
      { children }
    </div>
  );
};

ListItem.defaultProps = {
  borderInset: 0,
  handler: null,
  href: null,
  size: null,
  value: '',
};

export default ListItem;
