import { getCategoryV1Data, patchCategoryV1Data, postCategoryV1Data } from 'services/categoryV1';
import { APIBaseProps, APIResponse } from 'types/global';

export interface CategoryMappingProps extends APIBaseProps<CategoryMappingProps> {
  id?: number,
  sequenceNumber?: string,
  mappingType?: string,
  accountId?: number,
  accountNumber?: string,
  accountNickName?: string,
  transferAccountId?: number,
  transferAccountNumber?: string,
  transferAccountNickName?: string,
  keyword?: string,
  keyword2?: string,
  description?: string,
  categoryId?: number,
  categoryName?: string,
  categorySetId?: number,
  categorySetName?: string,
  type?: string,
  entryType?: string,
  baseTransactionType?: string,
  startDate?: Date,
  endDate?: Date,
  custom?: string,
  status?: string,
}

export async function getCategoryMappings(): Promise<APIResponse<CategoryMappingProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await getCategoryV1Data({
      url: 'categoryMappings',
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}

export async function patchCategoryMappings(row: CategoryMappingProps): Promise<APIResponse<CategoryMappingProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await patchCategoryV1Data({
      url: `categoryMappings/${row.id}`,
      data: {
        sequenceNumber: row.sequenceNumber,
        mappingType: row.mappingType,
        transferAccountId: row.transferAccountId,
        keyword: row.keyword,
        keyword2: row.keyword2,
        description: row.description,
        categoryId: row.categoryId,
        accountId: row.accountId,
        status: row.status,
      },
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}

export async function postCategoryMappings(row: CategoryMappingProps): Promise<APIResponse<CategoryMappingProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await postCategoryV1Data({
      url: 'categoryMappings',
      data: {
        sequenceNumber: row.sequenceNumber,
        mappingType: row.mappingType,
        transferAccountId: row.transferAccountId,
        keyword: row.keyword,
        keyword2: row.keyword2,
        description: row.description,
        categoryId: row.categoryId,
        accountId: row.accountId,
        status: row.status,
      },
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}
