import { AuthProps, login } from 'services/authV1/login';
import { getUser } from 'services/userV1/user';
// Utils
import { delay, getSessionStorageItem, removeSessionStorageItem, setSessionStorageItem } from 'utils';
import * as authActions from 'context/AuthContext/actions';
import * as authFlags from 'context/AuthContext/statusFlags';
import { Dispatch } from 'react';
import { AppDispatchProps } from 'types/global';

export async function onLoginSuccess(response: AuthProps, dispatch: Dispatch<AppDispatchProps>) {
  dispatch(authActions.setAuthState(authFlags.LOGIN));
  try {
    const user = {
      firstName: response.user.firstName,
      lastName: response.user.lastName,
      email: response.user.email,
      userName: response.user.userName,
      role: response.user.role,
      id: response.user.id,
    };
    const userSession = response.user.id;
    const v2tkn = {
      v2tkn: response.tokens.access.token,
      tokenExpiry: response.tokens.access.expires,
      refreshToken: response.tokens.refresh.token,
    };
    dispatch(authActions.setUser(user));
    dispatch(authActions.setToken(v2tkn));
    dispatch(authActions.setAuthState(authFlags.AUTHORISE_USER));
    setSessionStorageItem({ item: 'userSession', value: userSession });
    setSessionStorageItem({ item: 'v2tkn', value: v2tkn });
    setSessionStorageItem({ item: 'auth', value: true });
  } catch (error) {
    dispatch(authActions.setAuthState(authFlags.LOGIN_ERROR));
  }
}

export async function initialiseLogin(email: string, password: string, dispatch: Dispatch<AppDispatchProps>) {
  try {
    const {
      errorMessage,
      response,
    } = await login(email, password);
    if (response) {
      onLoginSuccess(response, dispatch);
    }
    if (errorMessage) {
      dispatch(authActions.setAuthState(authFlags.LOGIN_ERROR));
    }
  } catch (error) {
    dispatch(authActions.setAuthState(authFlags.LOGIN_ERROR));
  }
} 

export async function unauthoriseUser(dispatch: Dispatch<AppDispatchProps>) {
  dispatch(authActions.setAuthState(authFlags.UNAUTHORISE_USER));
  try {
    removeSessionStorageItem({ item: 'v2tkn' });
    removeSessionStorageItem({ item: 'userSession' });
    removeSessionStorageItem({ item: 'auth' });
    removeSessionStorageItem({ item: 'tokenExpiry' });
    removeSessionStorageItem({ item: 'refreshToken' });
    dispatch(authActions.setUser({}));
    dispatch(authActions.unauthUser());
  } catch (error) {
    dispatch(authActions.setAuthState(authFlags.UNAUTHORISE_USER_ERROR));
  }
}

export async function initialiseLogout(dispatch: Dispatch<AppDispatchProps>) {
  try {
    dispatch(authActions.setAuthState(authFlags.LOGOUT));
    await unauthoriseUser(dispatch);
    await delay(2000);
    window.location.reload();
    window.location.pathname = '/';
  } catch (error) {
    dispatch(authActions.setAuthState(authFlags.LOGOUT_ERROR));
  }
}

export const authUserReturn = (dispatch: Dispatch<AppDispatchProps>) => { // Relogins user back to app if session already there
  try {
    const token = getSessionStorageItem({ item: 'v2tkn' });
    const userToken = getSessionStorageItem({ item: 'userSession' });
    if (token && userToken) {
      dispatch(authActions.authorizeReturnUser(token));
    }
  } catch (error) {
    dispatch(authActions.setError(error.toString()));
  }
};

export async function authoriseUserReturn(dispatch: Dispatch<AppDispatchProps>) {
  try {
    dispatch(authActions.setAuthState(authFlags.AUTHORISE_USER_RETURN));
    const v2tkn = getSessionStorageItem({ item: 'v2tkn' });
    dispatch(authActions.setToken(v2tkn));
    if (v2tkn) {
      const userSession = getSessionStorageItem({ item: 'userSession' });
      const { errorMessage, response } = await getUser(userSession);
      if (response) {
        const user = {
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          userName: response.userName,
          role: response.role,
          id: response.id,
          passwordChangeDate: response.passwordChangeDate,
          authenticationType: response.authenticationType,
        };
        dispatch(authActions.setUser(user));
        dispatch(authActions.setToken(v2tkn));
        dispatch(authActions.authUser(authFlags.AUTHORISE_USER));
      }
      if (errorMessage) {
        dispatch(authActions.authUser(authFlags.AUTHORISE_USER_RETURN_ERROR));
      }
    }
  } catch (error) {
    dispatch(authActions.authUser(authFlags.AUTHORISE_USER_RETURN_ERROR));
  }
}
