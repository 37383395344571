import React from 'react';
import styles from './style/textField.module.css';

interface TextFieldProps {
  disabled?: boolean,
  handleBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  handleFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  handler?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  helper?: string,
  id?: string,
  value?: string | number,
  maxLength?: number,
  minLength?: number,
  maxNum?: number,
  minNum?: number,
  name?: string,
  label?: string,
  pattern?: string,
  required?: boolean,
  textArea?: boolean,
  type?: string,
}

const TextField = ({
  disabled,
  handleBlur,
  handleFocus,
  handler,
  helper,
  id,
  label,
  maxLength,
  minLength,
  maxNum,
  minNum,
  name,
  pattern,
  required,
  textArea,
  type,
  value,
}:TextFieldProps) => (
  <div className={`
    ${styles.textField} 
    ${disabled ? styles.textField_disabled : ''} 
    ${value ? styles.textField_hasValue : ''}`}
  >
    { textArea ? (
      <textarea
        disabled={disabled}
        id={id}
        data-testid={id}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handler}
        value={value || ''}
        required={required}
      />
    ) : (
      <input
        disabled={disabled}
        id={id}
        data-testid={id}
        maxLength={maxLength}
        minLength={minLength}
        max={maxNum}
        min={minNum}
        name={name}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handler}
        pattern={pattern}
        type={type}
        value={value || ''}
        required={required}
      />
    )}
    <div className={styles.textField__label}>{ label }</div>
    { helper && (
      <div className={styles.textField__helper}>{ helper }</div>
    )}
  </div>
);

TextField.defaultProps = {
  disabled: false,
  handleBlur: null,
  handleFocus: null,
  handler: null,
  helper: null,
  label: 'Text Field',
  maxLength: null,
  minLength: null,
  maxNum: null,
  minNum: null,
  name: null,
  pattern: '',
  required: null,
  textArea: false,
  type: 'text',
  value: false,
  id: null,
};

export default TextField;
