import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthContext, useAuthState, useAuthDispatch } from 'context/AuthContext';
import Routes from 'routes';
import Header from '../Header';
import Footer from '../Footer';
import Join from '../../pages/Join';
import LogoutTimer from '../LogoutTimer';

function AppRoot() {
  // const { auth } = useAuthState();
  // const auth = sessionStorage.getItem('auth');
  const { auth } = useAuthState();
  if (!auth) return (
    <>
      <Header />
      <Join />
      <Footer />
    </>
  );
  return (
    <Router>
      <Header />
      <Routes />
      {auth ? <LogoutTimer /> : ''}
      <Footer />
    </Router>       
  );
}

export default AppRoot;
