import { postAuthV1Data } from 'services/authV1';
import { UserProps } from 'services/userV1/user';
import { APIResponse } from 'types/global';

export interface AuthProps {
  user: UserProps,
  tokens: {
    access: { token: string, expires: Date },
    refresh: { token: string, expires: Date },
  }
}

export async function login(email: string, password: string): Promise<APIResponse<AuthProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await postAuthV1Data({
      data: { email, password },
      url: 'login',
      withAuth: false,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}
/*
export async function refresh(refreshToken: string): Promise<APIResponse> {
  let response = '';
  let errorMessage = '';  
  try {
    response = await postAuthV1Data({
      data: { refreshToken },
      url: 'refresh-tokens',
      withAuth: false,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}
*/