import { getUserV1Data } from 'services/userV1';
import { APIResponse } from 'types/global';

export interface UserProps {
  id?: number,
  userName?: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  passwordChangeDate?: Date,
  authenticationType?: string,
  status?: string,
  endDate?: Date,
  lastLoginDate?: Date,
  lockDate?: Date,
  profileId?: number,
  role?: string,
}

export async function getUser(id: number): Promise<APIResponse<UserProps>> {
  let response;
  let errorMessage = '';  
  try {
    response = await getUserV1Data({
      url: JSON.stringify(id),
      withAuth: true,
    });
  } catch (error) {
    errorMessage = error.message;
  }
  return { response, errorMessage };
}
