import axios from 'axios';
import config from 'config';
import { createApi } from 'utils/api';

const accountV1 = axios.create({
  baseURL: config.accountV1ApiBaseUrl,
});

const createAccountV1Api = createApi(accountV1);
export const getAccountV1Data = createAccountV1Api('get');
export const patchAccountV1Data = createAccountV1Api('patch');
export const postAccountV1Data = createAccountV1Api('post');
export const putAccountV1Data = createAccountV1Api('put');
export const deleteAccountV1Data = createAccountV1Api('delete');
