import React from 'react';
import styles from './style/modalContent.module.css';

interface ModalContentProps {
  children: React.ReactNode,
}

const ModalContent = ({ children }: ModalContentProps) => (
  <div className={styles.modalContent}>
    { children }
  </div>
);

export default ModalContent;
