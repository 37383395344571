import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Utils
import { useSetState } from 'utils';
import { timeFormatter } from 'utils/global';
import config from 'config';
// Auth Context
import { useAuthDispatch } from 'context/AuthContext';
import { initialiseLogout } from 'context/AuthContext/middleware';
// Styles
import styles from './style/logoutTimer.module.css';

function LogoutTimer() {
  const location = useLocation();
  const savedCallback = useRef<Function>();
  // Auth Context
  const authDispatch = useAuthDispatch();
  // State
  const [state, setState] = useSetState({
    remaining: null as (string|null),
    timer: Date.now() + config.logoutTimer,
    timingOut: false,
  });

  function initiateLogoutTimer() {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    const interval = setInterval(tick, 1000);
    return () => clearInterval(interval);
  }

  function logoutCallback() {
    const startDate = new Date();
    const endDate = new Date(state.timer);
    const remainingTime = timeFormatter(startDate, endDate);
    if (!remainingTime) {
      setTimeout(() => {
        initialiseLogout(authDispatch);
      }, 1000); 
    }
    setState({
      remaining: remainingTime,
      timingOut: (Number(endDate) - Number(startDate)) < 300000,
    });
  }

  function setSavedCallback() {
    savedCallback.current = logoutCallback;
  }

  useEffect(() => {
    setState({
      remaining: null,
      timer: Date.now() + config.logoutTimer,
      timingOut: false,
    });
  }, [location, setState]);

  useEffect(initiateLogoutTimer, []);
  useEffect(setSavedCallback);

  return (
    <>
      { state.timingOut ? (
        <div className={styles.main}>
          <div className={styles.logoutTimer} data-automation-id="sign-out">
            { state.remaining ? (`Logout in: ${state.remaining}`) : 'Logging out' }
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default LogoutTimer;
