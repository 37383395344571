import axios from 'axios';
import config from 'config';
import { createApi } from 'utils/api';

const categoryV1 = axios.create({
  baseURL: config.categoryV1ApiBaseUrl,
});

const createcategoryV1Api = createApi(categoryV1);
export const getCategoryV1Data = createcategoryV1Api('get');
export const patchCategoryV1Data = createcategoryV1Api('patch');
export const postCategoryV1Data = createcategoryV1Api('post');
export const putCategoryV1Data = createcategoryV1Api('put');
export const deleteCategoryV1Data = createcategoryV1Api('delete');
